import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

export const FETCH_DIGISERTS = 'api/FETCH_DIGISERTS'
export const FETCH_DIGISERTS_SUCCESS = 'api/FETCH_DIGISERTS_SUCCESS'
export const FETCH_DIGISERTS_ERROR = 'api/FETCH_DIGISERTS_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const fetchDigisertsAPI = () => {
  return axios.get(SERVER_URL + '/data/digiserts/')
    .then(response => response.data)
    .catch(err => {
      throw err
    })
}

function * fetchDigisertsSaga () {
  try {
    const res = yield call(fetchDigisertsAPI)
    yield put({ type: FETCH_DIGISERTS_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_DIGISERTS_ERROR, payload: e.message })
  }
}

export function * watchFetchDigiserts () {
  yield takeLatest(FETCH_DIGISERTS, fetchDigisertsSaga)
}

// Saga actions
export const fetchDigiserts = () => ({ type: FETCH_DIGISERTS, isFetching: true, hasFetched: false })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  canonical: null,
  error: null,
  digiserts: null // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_DIGISERTS:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        canonical: null,
        error: null
      })
    case FETCH_DIGISERTS_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        canonical: null
      })
    case FETCH_DIGISERTS_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        digiserts: payload.data,
        hasError: false,
        canonical: null,
        error: null
      })
    default:
      return state
  }
}
