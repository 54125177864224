import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { x } from '@xstyled/styled-components'
import { useCombobox } from 'downshift'
import { useCookie } from './ui/utils'

import sunrise from '../../../assets/images/weather/sunrise.svg'
import windy from '../../../assets/images/weather/windy.svg'
import sunny from '../../../assets/images/weather/sunny.svg'
import cloudy from '../../../assets/images/weather/cloudy.svg'
import pcloudy from '../../../assets/images/weather/pcloudy.svg'
import rain from '../../../assets/images/weather/rain.svg'
import thunder from '../../../assets/images/weather/thunder.svg'

const DefaultLocation = {
  slug: "cape-town",
  StationName: "Cape Town",
  Lat: "-33.963300",
  Lon: "18.462200",
  ObservedDate: "2022-02-16T07:00:00"
}

const pictures = {
  'sunny' : sunny,
  'windy' : windy,
  'cloudy': cloudy,
  'partlycloudyday': pcloudy,
  'showersday': rain,
  'modheavyrain': rain,
  'rainy': rain,
  'thunder': thunder
}

function GetDescription(Forecast) {
  return Forecast.Weather.Description + ' with ' + (Forecast.Weather.Visibility + ' visibility and ' + Forecast.Precipitation.Description).toLowerCase()
}
function getAffix(number) {
  switch( number.toString().slice(-1)) {
    case '1': return 'st'
    case '2': return 'nd'
    case '3': return 'rd'
    default: return 'th'
  }
}
function getDay(dateString) {
  const day = new Date(dateString)
  return [
    new Intl.DateTimeFormat('en-ZA', {weekday: 'short'}).format(day),
    day.getDate() + getAffix(day.getDate())
  ]
}
const WeatherDay = ({forecast}) => {
  if (forecast) {
    const day = getDay(forecast.Date)
    return (
      <x.div className='weather-list' borderWidth={{ sm: '0 1px 1px 0', lg: '0 1px 0 0' }} borderStyle='solid' borderColor='divider' p={{ sm: '15px 20px', wAdD: '0 10px 10px', article: '0 20px 15px' }} gridColumn={{ sm: '1 / 2', lg: 'unset'}}>
        <x.p mb={10}><strong>{day[0]}</strong> {day[1]}</x.p>
        <x.div display='flex' alignItems='center' justifyContent='space-between'>
          <x.img src={pictures[forecast.Day.Weather.Picture]} title={forecast.Day.Weather.Description} mr={{ sm: '20px', wAdD: '10px', article: '20px' }} w={45} />
          <x.p text='medium' textAlign='right'><strong>{forecast.Temperature.Max}&deg;</strong><br />{forecast.Temperature.Min}&deg;</x.p>
        </x.div>
      </x.div>
    )
  }
  return false
}
const ComboBox = ({locations, setLocation}) => {
  const [items, setItems] = useState(locations)
  useEffect(()=>setItems(locations), [locations])
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: items,
    itemToString: item => item.StationName,
    onSelectedItemChange: changes => setLocation(changes.selectedItem),
    onInputValueChange: ({inputValue}) => {
      setItems(
        locations.filter(item =>
          item.StationName.toLowerCase().startsWith(inputValue.toLowerCase()),
        ),
      )
    },
  })

  return (
    <x.div position='relative' my={{ sm: '10px', lg: '0'}}>
      <x.div {...getComboboxProps()} border='1px solid #F0F0F0' p={5}>
        <x.input {...getInputProps()} placeholder='Change location' w={{ sm: '100%', lg: 'auto'}} />
        <x.button
          type='button'
          position='absolute'
          right={8}
          {...getToggleButtonProps()}
          aria-label={'toggle menu'}
        >
          &#8595;
        </x.button>
      </x.div>
      <x.ul {...getMenuProps()} position='absolute' top='100%' h={isOpen ? '250px' : 'initial'} w='100%' overflow='auto' bg='#F5F5F5' zIndex='100'>
        {isOpen &&
          items.map((item, index) => (
            <x.li p={5} backgroundColor={{ _: '#F5F5F5' , hover: '#F0F0F0' }}
              key={`${item}${index}`}
              {...getItemProps({item, index})}
            >
              {item.StationName}
            </x.li>
          ))}
      </x.ul>
    </x.div>
  )
}
const WeatherWidget = props => {
  const [manualLocation, setManualLocation] = useCookie('weather-city', '')
  const [location, setLocation] = useState({})
  const [locations, setLocations] = useState([])
  const [weather, setWeather] = useState(false)

  const updateSelectedItem = item => {
    setLocation(item)
    setManualLocation(item.slug)
  }

  useEffect(()=>{
    const newLocation = locations.filter( item => {
      return item.slug === manualLocation
    })
    if (newLocation.length) {
      setLocation(newLocation[0])
    }
  }, [manualLocation, locations])

  useEffect(()=>{
    if (!manualLocation) {
      axios.get('/data/weather/locations/closest')
        .then(resp => resp.data)
        .then(data => setLocation(data))
    }
  }, [])
  useEffect(()=>{
    axios.get('/data/weather/locations')
      .then(resp => resp.data)
      .then(data => setLocations(data))
  }, [])
  useEffect(()=>{
    if (location.slug) {
      axios.get('/data/weather/weather/'+location.slug)
        .then(resp => resp.data)
        .then(data => {
          return setWeather(data[0])
        })
    }
  }, [location])
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' mt={50} mb={30}>
        <x.div borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={30} display='flex' flexDirection={{ sm: 'column', lg: 'row' }}>
          <x.h1 text='h4' mr={20}>{location.StationName} Weather</x.h1>
          <ComboBox locations={locations} setLocation={updateSelectedItem} />
        </x.div>
        <x.div display='grid' gridTemplateColumns={{ lg: 'minmax(min-content, 400px) repeat(6, 1fr)' }} borderWidth='0 0 8px 0' borderStyle='solid' borderColor='red' alignItems='flex-end'>
          <x.div borderWidth={{ sm: '0 0 1px 0', lg: '0 1px 0 0' }} borderStyle='solid' borderColor='divider' p={{ sm: '15px 20px', wAdD: '0 10px 10px', article: '0 20px 15px' }} pt='0' gridColumn={{ sm: '1 / span 2', lg: 'unset'}}>
            <x.p text='large' fontWeight='semiBold'>Today</x.p>
            <x.div mt={20} display='grid' gridTemplateColumns={{ sm: '50% 50%', lg: 'minmax(min-content, 144px) 1fr' }} alignItems='center'>
              <x.div borderWidth='0 1px 0 0' borderStyle='solid' borderColor='divider' display='flex' alignItems='center' justifyContent='space-between'>
                {!weather ? (
                  <>
                    <x.img src={sunny} title='Sunny' mr={{ sm: '20px', wAdD: '10px', article: '20px' }} w={70} />
                    <x.p text='large' pr={20} textAlign='right'><strong>--&deg;</strong><br />--&deg;</x.p>
                  </>
                ) : (
                  <>
                    <x.img src={pictures[weather.Forecasts.ForecastDay0.Day.Weather.Picture]} title={weather.Forecasts.ForecastDay0.Day.Weather.Description} mr={{ sm: '20px', wAdD: '10px', article: '20px' }} w={70} />
                    <x.p text='large' pr={20} textAlign='right'><strong>{weather.Forecasts.ForecastDay0.Temperature.Max}&deg;</strong><br />{weather.Forecasts.ForecastDay0.Temperature.Min}&deg;</x.p>
                  </>
                )}
              </x.div>
              <x.div p='0 20px'>
                <x.p text='large'>{weather ? GetDescription(weather.Forecasts.ForecastDay0.Day) : ''}</x.p>
              </x.div>
            </x.div>
          </x.div>
          {weather &&
            <>
              <WeatherDay forecast={weather.Forecasts.ForecastDay1} />
              <WeatherDay forecast={weather.Forecasts.ForecastDay2} />
              <WeatherDay forecast={weather.Forecasts.ForecastDay3} />
              <WeatherDay forecast={weather.Forecasts.ForecastDay4} />
              <WeatherDay forecast={weather.Forecasts.ForecastDay5} />
              <WeatherDay forecast={weather.Forecasts.ForecastDay6} />
            </>
          }
        </x.div>
        <x.div p={10} display='flex' flexDirection='row' alignItems='middle' justifyContent='right'>
          <x.img src={sunrise} mr={10} /><x.p text='medium'><x.strong mr={10}>Sunrise {weather ? weather.Forecasts.ForecastDay0.Astrological.SunRise : '--:--'}</x.strong><x.span>Sunset {weather ? weather.Forecasts.ForecastDay0.Astrological.Sunset : '--:--'}</x.span></x.p>
        </x.div>
      </x.div>
    </>
  )
}

export default WeatherWidget
