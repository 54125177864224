import axios from 'axios'
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { ImageCalculator } from 'ion-image'
import IonArticle from 'ion-article'
import { fetchArticlesAPI } from './articles'
import { USER_SET_VISITORID } from './user'
import Cache from '../lib/cache'

export const FETCH_ARTICLE_CONTENT = 'api/FETCH_ARTICLE_CONTENT'
export const FETCH_ARTICLE_CONTENT_SUCCESS = 'api/FETCH_ARTICLE_CONTENT_SUCCESS'
export const FETCH_ARTICLE_CONTENT_ERROR = 'api/FETCH_ARTICLE_CONTENT_ERROR'
export const FETCH_ARTICLE = 'api/FETCH_ARTICLE'
export const FETCH_ARTICLE_SUCCESS = 'api/FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_URL_VERIFIED = 'api/FETCH_ARTICLE_URL_VERIFIED'
export const FETCH_ARTICLE_ERROR = 'api/FETCH_ARTICLE_ERROR'
export const FETCH_ARTICLE_NOT_FOUND = 'api/FETCH_ARTICLE_NOT_FOUND'
export const SET_PREVIEW_ARTICLE = 'api/SET_PREVIEW_ARTICLE'
export const SEED_ARTICLE = 'api/SEED_ARTICLE'
export const SET_PROCESSED_ARTICLE = 'api/SET_PROCESSED_ARTICLE'

export const SUBMIT_COMPETITION = 'api/SUBMIT_COMPETITION'
export const SUBMIT_COMPETITION_SUCCESS = 'api/SUBMIT_COMPETITION_SUCCESS'
export const SUBMIT_COMPETITION_ERROR = 'api/SUBMIT_COMPETITION_ERROR'
export const OOVVUU_ARTICLE_RENDER = 'api/OOVVUU_ARTICLE_RENDER'

export const TOGGLE_FONT_SIZE = 'article/TOGGLE_FONT_SIZE'

export const SOCIAL_SHARE_PLATFORM = 'api/SOCIAL_SHARE_PLATFORM'

const SERVER_URL = typeof window !== 'undefined' ? '/data/content' : process.env.RAZZLE_CONTENT
const CANONICAL_FETCHER_URL = typeof window !== 'undefined' ? '/data/canonical' : process.env.RAZZLE_CANONICAL_FETCHER_URL

const cache = Cache()

const resizeImages = (widths, bodyHTML) => {
  const images = bodyHTML.match(/<img [^>]+>/g)
  if (images) {
    const calc = new ImageCalculator()
    const shape = 'original'
    const resizeURL = process.env.RAZZLE_RESIZE_URL

    for (const imageTag of images) {
      const image = JSON.parse(imageTag
        .replace(/['"][ ]+/g, '",')
        .replace(/([ ,])([a-z0-9-]+)=/gi, '$1"$2":')
        .replace(/<img\s+/, '{')
        .replace(/[,/ ]*>$/, '}')
      )

      if (image.width && parseInt(image.width) === 1 &&
        image.height && parseInt(image.height) === 1) {
        continue
      }
      let imgOffsetX = parseInt(image.offsetx) || 0
      let imgOffsetY = parseInt(image.offsety) || 0
      let imgCropWidth = parseInt(image.cropwidth) || 0
      let imgCropHeight = parseInt(image.cropwidth) || 0
      const imageWidth = parseInt(image.imageWidth)
      const imageHeight = parseInt(image.imageHeight)
      if ('imageCrop' in image) {
        const crops = image.imageCrop.split('/')
        imgOffsetX = Math.round((parseFloat(crops[0]) || 0) * imageWidth)
        imgOffsetY = Math.round((parseFloat(crops[1]) || 0) * imageHeight)
        imgCropWidth = Math.round((parseFloat(crops[2]) || 1) * imageWidth)
        imgCropHeight = Math.round((parseFloat(crops[3]) || 1) * imageHeight)
      }
      const sources = []
      let defaultSrc = ''
      for (let i = 0; i < widths.length; i++) {
        const width = widths[i].imageWidth
        const height = calc.calcHeight(shape, width) || width * 100
        const { offsetx, offsety, cropWidth, cropHeight } = calc.getCropCoordsForShape(shape,
          width, height, imageWidth, imageHeight,
          imgOffsetX, imgOffsetY, imgCropWidth, imgCropHeight,
          0, 0)
        const src = calc.buildImageUrl(resizeURL, '', width, height, image.src, offsetx, offsety, cropWidth, cropHeight, false)
        if (!defaultSrc) {
          defaultSrc = src
        }
        const webPSrc = calc.buildImageUrl(resizeURL, '', width, height, image.src, offsetx, offsety, cropWidth, cropHeight, true)
        const hdSrc = calc.buildImageUrl(resizeURL, '', width * 2, height * 2, image.src, offsetx, offsety, cropWidth, cropHeight, false)
        const screenWidth = widths[i].screenWidth ? widths[i].screenWidth : widths[i - 1].screenWidth + 1
        const isMax = screenWidth === widths[i].screenWidth
        sources.push(
          `<source type="image/webp" media="(${isMax ? 'max' : 'min'}-width: ${screenWidth}px)" srcSet="${webPSrc}" />` +
          `<source type="image/jpeg" media="(${isMax ? 'max' : 'min'}-width: ${screenWidth}px)" srcSet="${hdSrc} 1.5x" />` +
          `<source type="image/jpeg" media="(${isMax ? 'max' : 'min'}-width: ${screenWidth}px)" srcSet="${src}" />`
        )
      }
      const pictureTag =
      '<picture>' +
        sources.join('') +
        `<img className=${image.class} src="${defaultSrc}" loading="lazy" />` +
      '</picture>'
      bodyHTML = bodyHTML.replace(imageTag, pictureTag)
    }
  }
  return bodyHTML
}

export async function fetchArticleAPI (contentKey) {
  const key = '/article/' + contentKey
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios
    .get(SERVER_URL + key)
    .then(response => {
      return cache.set(key, response.data, 60000)
    })
    .catch(error => {
      console.error('ERROR fetching', SERVER_URL + key, '-', error.message)
      throw error
    })
}

async function fetchAuthorAPI (contentKey) {
  const key = '/author/' + process.env.RAZZLE_TITLE_KEY + '/' + contentKey
  const result = await cache.get(key)
  if (result) {
    return result
  }
  try {
    const response = await axios.get(SERVER_URL + key)
    return cache.set(key, response.data, 60000 * 30)
  } catch (error) {
    console.error('ERROR fetching', SERVER_URL + key, '-', error.message)
    throw error
  }
}

const COMPETITION_SERVER_URL =
  typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

async function fetchCompetition (competitionSlug) {
  const key = '/competition/' + competitionSlug
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios
    .get(COMPETITION_SERVER_URL + '/data/competition/' + competitionSlug)
    .then(response => cache.set(key, response.data, 60000))
    .catch(error => {
      console.error('ERROR fetching', COMPETITION_SERVER_URL + '/data/competition/' + competitionSlug, '-', error.message)
      throw error
    })
}

const submitCompetitionAPI = formData => {
  return axios
    .post(COMPETITION_SERVER_URL + '/data/competition', formData)
    .then(data => data.data)
    .catch(error => console.error('ERROR submitting', COMPETITION_SERVER_URL + '/data/competition/', '-', error.message)
    )
}

const TIMELINE_SERVER_URL = typeof window !== 'undefined' ? '/data/timelines' : process.env.RAZZLE_TIMELINES

function getTags (primaryTag, secondaryTags) {
  const tags = []
  if (secondaryTags && secondaryTags.length > 0) {
    secondaryTags.map(tag => tags.push(tag.label))
  }
  if (primaryTag && primaryTag.length > 0) {
    primaryTag.map(tag => tags.push(tag.label))
  }
  return tags
}

async function fetchTimelines (slugs) {
  return Promise.all(slugs.map(slug => {
    return axios.get(TIMELINE_SERVER_URL + '/' + slug)
      .then(data => {
        const timeline = {}
        timeline.title = data.data?.title
        timeline.cards = data.data?.timelineCards.map(card => {
          const item = {}
          item.title = card.timelineMarker
          item.cardTitle = card.cardTitle
          if (card.cardUrl) { item.url = card.cardUrl }
          item.cardSubtitle = card.cardSubtitle
          item.cardDetailedText = card.cardDetailedText
          if (card.cardMediaAsset || card.mediaSourceUrl) {
            item.media = {
              type: card.mediaType || 'IMAGE',
              source: {
                url: card.cardMediaAsset || card.mediaSourceUrl
              }
            }
          }
          return item
        })
        const tempData = {}
        tempData[slug] = timeline.cards
        return tempData
      })
      .catch(error => {
        console.error('Timeline fetch error:', error.message)
        return null
      })
  }))
    .then((promisesArray) => {
      const sanitisedArray = promisesArray.filter(item => item)
      const resultObject = sanitisedArray.reduce((acc, obj) => {
        const key = Object.keys(obj)[0]
        acc[key] = obj[key]
        return acc
      }, {})
      return resultObject
    })
}

async function fetchCanonicalURL (contentKey) {
  return axios
    .get(CANONICAL_FETCHER_URL + '/' + contentKey)
    .catch(error => {
      console.error('ERROR fetching', CANONICAL_FETCHER_URL + '/' + contentKey, '-', error.message)
      throw error
    })
}

async function fetchIonArticle (content) {
  return axios
    .get(SERVER_URL + '/preview/' + content)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('ERROR fetching', SERVER_URL + '/preview/' + content, '-', error.message)
      throw error
    })
}

function * fetchArticleContentSaga ({ contentKey }) {
  try {
    const res = yield call(fetchArticleAPI, contentKey)
    yield put({ type: FETCH_ARTICLE_CONTENT_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_ARTICLE_CONTENT_ERROR, payload: e.message })
  }
}

export function * watchFetchArticleContent () {
  yield takeLatest(FETCH_ARTICLE_CONTENT, fetchArticleContentSaga)
}

function fetchVisitorId () {
  let result = false
  if (typeof window !== 'undefined' && window.localStorage) {
    result = window.localStorage.getItem('visitorId')
  }
  if (!result) {
    result = Math.random().toString(36).substr(2, 9)
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('visitorId', result)
    }
  }
  return result
}

function * fetchArticleSaga ({ contentKey }) {
  try {
    const res = yield call(fetchArticleAPI, contentKey)
    const article = new IonArticle(res)
    article.canonicalUri = '/' + article.getCanonicalUri()
    const competitionSlug = article.getCompetitionSlug()
    const timelineSlugs = article.getTimelineSlugs()
    if (timelineSlugs) {
      const timelines = yield call(fetchTimelines, timelineSlugs)
      article.timelines = timelines
    }
    if (competitionSlug) {
      try {
        article.competition = yield call(fetchCompetition, competitionSlug)
        article.competitionExpired = false
      } catch (e) {
        article.competitionExpired = true
      }
    }
    article.relatedArticles = article.relatedArticles.map(relatedArticle => {
      relatedArticle.image = {}
      relatedArticle.image.width = relatedArticle.imageWidth
      relatedArticle.image.height = relatedArticle.imageHeight
      relatedArticle.image.crop = relatedArticle.crop
      relatedArticle.image.crops = relatedArticle.crops
      relatedArticle.image.url = relatedArticle.imageUrl
      relatedArticle.image.externalUrl = relatedArticle.externalUrl
      return relatedArticle
    })
    if (article.titleKey !== process.env.RAZZLE_TITLE_KEY) {
      const canonicalResult = yield call(fetchCanonicalURL, contentKey)
      article.canonicalUri = canonicalResult.data.url
    }
    const secondaryTags = {}
    for (const e of article.secondaryTags || []) {
      secondaryTags[e.slug] = {
        sectionSlug: e.sectionSlug,
        uuid: e.uuid,
        label: e.label
      }
    }
    article.secondaryTags = []
    for (const e in secondaryTags) {
      article.secondaryTags.push({
        slug: e,
        sectionSlug: secondaryTags[e].sectionSlug,
        uuid: secondaryTags[e].uuid,
        label: secondaryTags[e].label
      })
    }
    let authorArticles = []
    let author = {}
    if (article.authors && article.authors[0] && article.authors[0].hasAuthorPage) {
      try {
        authorArticles = yield call(fetchArticlesAPI, article.authors[0].slug, 0, 4)
        if (article.authors[0].uuid) {
          // For CosMoS, this is identical to below and can be removed after migration
          author = yield call(fetchAuthorAPI, article.authors[0].uuid.replace('authors/', ''))
        } else {
          author = yield call(fetchAuthorAPI, article.authors[0].name)
        }
      } catch (e) {
        console.error('ERROR fetching authorArticles for', article.authors[0].slug, '-', e.message)
      }
    }

    let youMayLikeArticles = []
    try {
      youMayLikeArticles = yield call(fetchArticlesAPI, article.section, 0, 6)
    } catch (e) {
      console.error('ERROR fetching youMayLikeArticles for', article.sectionSlug, '-', e.message)
    }

    article.bodyHTML = resizeImages([{ imageWidth: 420, screenWidth: 640 }, { imageWidth: 610, screenWidth: false }], article.bodyHTML)
    article.bodyHTML = article.bodyHTML.replace('<!-- [AD_SLOT] -->', '<ad-slot/>')
    article.bodyHTML = '<!--sse-->' + article.bodyHTML + '<!--/sse-->'

    article.videoSources = []
    if (article.videos && article.videos[0].sources) {
      const sources = article.videos[0].sources
      article.videoSources = Object.keys(sources).map(key => {
        return { src: sources[key].url || sources[key].mp4.url, type: sources[key].type || 'video/mp4', label: key }
      })
    }

    article.visitorId = yield select(getVisitorId)
    article.sessionId = yield select(getSessionId)
    if (!article.visitorId) {
      article.visitorId = fetchVisitorId()
      yield put({ type: USER_SET_VISITORID, payload: article.visitorId })
    }
    yield put({
      type: FETCH_ARTICLE_SUCCESS,
      payload: article,
      authorArticles: authorArticles.contents,
      youMayLikeArticles: youMayLikeArticles.contents,
      author,
      path: article.canonicalUri,
      modified: article.modified,
      visitorId: article.visitorId,
      sessionId: article.sessionId,
      tags: getTags(article.primaryTag, article.secondaryTags)
    })
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put({ type: FETCH_ARTICLE_NOT_FOUND, payload: e.message })
    } else {
      console.error('ERROR fetchArticleSaga for', contentKey, '-', e.message)
      yield put({ type: FETCH_ARTICLE_ERROR, payload: e.message })
    }
  }
}

export function * watchFetchArticle () {
  yield takeLatest(FETCH_ARTICLE, fetchArticleSaga)
}

function * setPreviewArticleSaga ({ payload }) {
  try {
    const res = yield call(fetchIonArticle, payload)
    const article = new IonArticle(res)
    let authorArticles = []
    let youMayLikeArticles = []
    let author = {}
    article.bodyHTML = resizeImages([{ imageWidth: 420, screenWidth: 640 }, { imageWidth: 610, screenWidth: false }], article.bodyHTML)
    if (article.authors && article.authors[0] && article.authors[0].hasAuthorPage) {
      try {
        authorArticles = yield call(fetchArticlesAPI, article.authors[0].slug, 0, 4)
        if (article.authors[0].uuid) {
          author = yield call(fetchAuthorAPI, article.authors[0].uuid)
        } else {
          author = yield call(fetchAuthorAPI, article.authors[0].name)
        }
      } catch (e) {
        console.error('Unable to fetch authorArticles', e.message)
      }
    }
    try {
      youMayLikeArticles = yield call(fetchArticlesAPI, article.section, 0, 6)
    } catch (e) {
      console.error('ERROR fetching youMayLikeArticles for', article.sectionSlug, '-', e.message)
    }
    yield put({
      type: FETCH_ARTICLE_SUCCESS,
      payload: article,
      authorArticles: authorArticles.contents,
      youMayLikeArticles: youMayLikeArticles.contents,
      author,
      path: article.canonicalUri,
      modified: article.modified,
      visitorId: article.visitorId,
      sessionId: article.sessionId,
      tags: getTags(article.primaryTag, article.secondaryTags)
    })
  } catch (e) {
    console.error('ERROR setPreviewArticleSaga', e.message)
    yield put({ type: FETCH_ARTICLE_ERROR, payload: e.message })
  }
}

function * submitCompetitionSaga ({ payload }) {
  const formData = payload
  try {
    const res = yield call(submitCompetitionAPI, formData)
    if (res.success) {
      yield put({ type: SUBMIT_COMPETITION_SUCCESS })
    } else {
      yield put({ type: SUBMIT_COMPETITION_ERROR, payload: res.errors })
    }
    // let article = yield select(getArticle)
    // article.bodyHTML = article.bodyHTML.replace(/(<form([^]*)>)([.]*)(<\/form>)/g, '$1<p id="success-message">You have successfully entered our competition</p>$2')
    // yield put({ type: SUBMIT_COMPETITION_SUCCESS, payload: article })
  } catch (e) {
    console.error('ERROR while submitting to competition', e.message)
    yield put({
      type: SUBMIT_COMPETITION_ERROR,
      payload: { message: e.message }
    })
  }
}

export function * watchPreviewArticle () {
  yield takeLatest(SET_PREVIEW_ARTICLE, setPreviewArticleSaga)
}

export function * watchSubmitCompetition () {
  yield takeEvery(SUBMIT_COMPETITION, submitCompetitionSaga)
}

// Saga actions
export const getVisitorId = state => state.user.visitorId
export const getSessionId = state => state.user.sessionId
export const getArticle = state => state.article.article
export const fetchArticleContent = contentKey => ({ type: FETCH_ARTICLE_CONTENT, isFetching: true, hasFetched: false, contentKey })
export const articleUrlVerified = () => ({ type: FETCH_ARTICLE_URL_VERIFIED })
export const fetchArticle = contentKey => ({ type: FETCH_ARTICLE, isFetching: true, hasFetched: false, contentKey })
export const setPreviewArticle = data => ({ type: SET_PREVIEW_ARTICLE, payload: data })
export const seedArticle = data => ({ type: SEED_ARTICLE, payload: data })
export const submitCompetition = data => ({ type: SUBMIT_COMPETITION, payload: data })
export const setProcessedArticle = () => ({ type: SET_PROCESSED_ARTICLE })
export const oovvuuArticleView = payload => ({ type: OOVVUU_ARTICLE_RENDER, payload })
export const toggleFontSize = () => ({ type: TOGGLE_FONT_SIZE })
export const getSocialSharePlatform = (data) => ({ type: SOCIAL_SHARE_PLATFORM, payload: data })

function mapArticleFields (article) {
  // eslint-disable-next-line no-undef
  const partialArticle = JSON.parse(JSON.stringify(article))
  partialArticle.images = [article.image]
  partialArticle.abstract = article.bodyHTML
  delete partialArticle.bodyHTML
  return partialArticle
}

export const initialState = {
  lastFetch: 0,
  fontSize: 17,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasProcessed: false,
  hasError: false,
  is404: false,
  checkCanonical: false,
  modified: null,
  canonical: null,
  error: null,
  article: null, // do not mutate these
  previewArticle: false,
  authorArticles: [],
  youMayLikeArticles: [],
  tags: [],
  socialSharePlatform: null
}

export const Reducer = (
  state = initialState,
  { type, payload, author, authorArticles, path, modified, meta, youMayLikeArticles, tags }
) => {
  switch (type) {
    case SET_PREVIEW_ARTICLE:
      return Object.assign({}, state, {
        previewArticle: true
      })
    case SEED_ARTICLE: {
      const isFullArticle = !payload.displayTag
      if (state.article?.contentKey === payload.contentKey) {
        return state
      }
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: !isFullArticle,
        hasFetched: isFullArticle,
        hasProcessed: false,
        hasError: false,
        is404: false,
        checkCanonical: false,
        modified: null,
        canonical: null,
        error: null,
        article: isFullArticle ? payload : mapArticleFields(payload),
        author: isFullArticle ? undefined : payload.author,
        preloaded: true,
        previewArticle: false
      })
    }
    case SET_PROCESSED_ARTICLE:
      return Object.assign({}, state, {
        hasProcessed: true
      })
    case FETCH_ARTICLE_URL_VERIFIED:
      return Object.assign({}, state, {
        checkCanonical: false
      })
    case FETCH_ARTICLE_CONTENT:
      // Do not update the state
      return state
    case FETCH_ARTICLE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        is404: false,
        hasError: false,
        checkCanonical: false,
        canonical: null,
        error: null
      })
    case FETCH_ARTICLE_CONTENT_ERROR:
      // Do not update the state
      return state
    case FETCH_ARTICLE_NOT_FOUND:
      return Object.assign({}, state, {
        hasError: true,
        is404: true,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        checkCanonical: false,
        canonical: null
      })
    case FETCH_ARTICLE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        is404: false,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        checkCanonical: false,
        canonical: null
      })
    case FETCH_ARTICLE_CONTENT_SUCCESS:
      // Do not update the state
      return state
    case FETCH_ARTICLE_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        hasProcessed: false,
        preloaded: false,
        isFetching: false,
        didInvalidate: false,
        article: payload,
        authorArticles,
        youMayLikeArticles,
        checkCanonical: true,
        modified,
        canonical: path,
        author,
        tags,
        hasError: false,
        is404: false,
        error: null
      })
    case SOCIAL_SHARE_PLATFORM:
      return Object.assign({}, state, {
        socialSharePlatform: payload
      })
    case SUBMIT_COMPETITION:
      return Object.assign({}, state, {
        isSubmitting: true,
        hasSubmitted: false,
        hasErrorSubmit: false,
        formData: payload
      })
    case SUBMIT_COMPETITION_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: true,
        hasErrorSubmit: false
      })
    case SUBMIT_COMPETITION_ERROR:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: false,
        hasErrorSubmit: true,
        message: payload
      })
    case TOGGLE_FONT_SIZE:
      return Object.assign({}, state, {
        fontSize: state.fontSize === 21 ? 13 : state.fontSize + 2
      })
    default:
      return state
  }
}
