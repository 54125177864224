import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'

export const FETCH_OTHER_PUBLICATIONS_ARTICLES = 'api/FETCH_OTHER_PUBLICATIONS_ARTICLES'
export const FETCH_OTHER_PUBLICATIONS_ARTICLES_SUCCESS = 'api/FETCH_OTHER_PUBLICATIONS_ARTICLES_SUCCESS'
export const FETCH_OTHER_PUBLICATIONS_ARTICLES_ERROR = 'api/FETCH_OTHER_PUBLICATIONS_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/other-publications' : process.env.RAZZLE_OTHER_PUBLICATIONS
const OTHER_ARTICLES_SECTION = 'newspapertitles'
const cache = Cache()

async function fetchArticlesAPI (section, offset, limit, filter) {
  if (offset === 0) {
    const result = await cache.get(section)
    if (result && result.contents && result.contents.length >= limit) {
      // console.log('fetchArticlesAPI', 'HIT', section, limit)
      result.contents = result.contents.slice(0, limit)
      return result
    }
    limit = Math.max(10, limit)
  }
  return axios.get(SERVER_URL + '/' + offset + '/' + (limit * 2) + '/' + section)
    .then(response => {
      const data = response.data.filter(article => ('publication' in article) && filter.indexOf(article.publication) >= 0).slice(0, limit)

      if (offset === 0) {
        return cache.set(section, data)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchOtherPublicationsArticlesSaga ({ section, offset, limit, filter }) {
  try {
    const res = yield call(fetchArticlesAPI, section, offset, limit + 1, filter)
    yield put({ type: FETCH_OTHER_PUBLICATIONS_ARTICLES_SUCCESS, payload: res, section })
  } catch (e) {
    yield put({ type: FETCH_OTHER_PUBLICATIONS_ARTICLES_ERROR, payload: e.message, section })
  }
}

export function * watchFetchOtherPublicationsArticles () {
  yield takeLatest(FETCH_OTHER_PUBLICATIONS_ARTICLES, fetchOtherPublicationsArticlesSaga)
}

// Saga actions
export const fetchOtherPublicationsArticles = (section, offset, limit, filter) => ({ type: FETCH_OTHER_PUBLICATIONS_ARTICLES, section: OTHER_ARTICLES_SECTION, offset, limit, filter })

const initialState = {
  newspapertitles: {
    didInvalidate: false,
    isFetching: false,
    hasFetched: false,
    hasError: false,
    error: null,
    articles: [] // do not mutate these
  }
}

export const Reducer = (state = initialState, { type, payload, meta, section }) => {
  const newPayload = {}
  switch (type) {
    case FETCH_OTHER_PUBLICATIONS_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_OTHER_PUBLICATIONS_ARTICLES_ERROR:
      newPayload[section] = Object.assign({}, state[section], {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
      return Object.assign({}, state, newPayload)
    case FETCH_OTHER_PUBLICATIONS_ARTICLES_SUCCESS:
      newPayload[section] = Object.assign({}, state[section], {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload
      })
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
