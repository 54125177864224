import {useRef} from 'react'
//import MenuArrow from '../../../../assets/images/icons/accordian.svg'
import './CSSAccordion.css'
import { x } from '@xstyled/styled-components'

const AccordionItem = ({title, name, prefix, children}) => {
  const input = useRef(null)
  return (
    <x.div className='tab'>
      <x.input type='radio' name={name} id={'tab-' + prefix + title} ref={input} />
      <x.label className='tab-label' borderRadius={3} p='10px 15px' mb={5} bg='#F5F5F5'
        display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'
        fontWeight='600' color='#434343' htmlFor={'tab-' + prefix + title} onClick={(e) => {
        if (input.current.checked) {
          input.current.checked = false
          e.preventDefault()
        }
        }} >{title}</x.label>
      <x.div className='tab-content'>
        {children}
      </x.div>
    </x.div>
  )
}

const Accordion = ({children}) => {
  return (
    <div className='tabs'>
      {children}
    </div>
  )
}

export {AccordionItem, Accordion}
