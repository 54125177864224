"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEmpty = isEmpty;
exports.processError = processError;
exports["default"] = void 0;

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function isEmpty(value) {
  return value === undefined || value === null || _typeof(value) === 'object' && Object.keys(value).length === 0 || typeof value === 'string' && value.trim().length === 0;
}

function processError(res, error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    res.status(error.response.status).send(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('Server Error:', error.message);
    res.status(502).send(error.message);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    res.status(500).send(error.message);
  }

  console.log(error.config);
}
/**
 * Processes the params and POSTs to ION Analytics 
 * @param {string} eventType - pageView | ping | adRequest | adRender | navigation
 * @param {string} pageType - home | section | article | static
 * @param {string} userId - user id / visitor id
 * @param {string} titleKey - e.g. iol, dvd, glam
 * @param {string} sessionId - session id
 * @param {string} locationUrl - url
 * @param {object} eventData - Event-type specific additional data - author, section, clickWidget, desinationUrl, referrerUrl, scrollDepth, ad {}
 * @returns {promise<response>}
 */


var postAnalytics = function postAnalytics(eventType, pageType, userId, titleKey, sessionId, locationUrl, eventData) {
  var timestamp = new Date().toISOString();
  var screenWidth = 0;
  var screenHeight = 0;
  var browser = 'unknown';

  if (typeof window !== 'undefined') {
    screenWidth = window.outerWidth;
    screenHeight = window.outerHeight;

    if (navigator.userAgent.indexOf('Chrome') != -1) {
      browser = 'Google Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      browser = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      browser = 'Mozilla Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1 || navigator.userAgent.indexOf('Trident/') != -1) {
      browser = 'Internet Explorer';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      browser = 'Microsoft Edge';
    }
  }

  var _eventData = Object.assign({
    eventType: eventType,
    pageType: pageType,
    userId: userId,
    titleKey: titleKey,
    sessionId: sessionId,
    locationUrl: locationUrl,
    timestamp: timestamp,
    screenWidth: screenWidth,
    screenHeight: screenHeight,
    browser: browser
  }, eventData);

  return _axios["default"].post(process.env.RAZZLE_POST_ANALYTICS_SERVICE, JSON.stringify(_eventData), {
    headers: {
      'Content-Type': 'text/plain'
    }
  })["catch"](function (error) {
    console.error('pageView event error:', error.message);
    throw error;
  });
};

var _default = postAnalytics;
exports["default"] = _default;