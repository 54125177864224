import React, { useState, useEffect } from 'react'
import { x } from '@xstyled/styled-components'
import StyledLink from '../components/ui/Link'
import { db } from '../../client/db'
import { getCookie } from './ui/utils'

import bellIcon from '../../../assets/images/icons/notifications-ico.svg'

const NotificationsBell = (props) => {
  const [ UnreadCount, setUnreadCount ] = useState(0)
  const clickSource = 'Notifications Bell'
  useEffect(() => {
    const NotificationsLastViewed = getCookie('NotificationsLastViewed')
    db.messages.toArray().then(articles =>
      setUnreadCount((articles.filter(article =>
        Date.parse(article.timestamp) > Date.parse(NotificationsLastViewed))).length
      )
    )
  }, [])

  return (
    <StyledLink to='/my-notifications' mr={10} title='View recent push notifications.' position='relative' visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource} section={props.section}>
      <x.img src={bellIcon} alt={'View ' + UnreadCount + ' recent articles pushed to you.'} />
      {UnreadCount > 0 &&
        <x.span bg='red' w='12px' h='12px' borderRadius={100} position='absolute' top={-2} right={-3} display='flex' alignItems='center' justifyContent='center' color='white' lineHeight={1} fontSize={9}>
          <x.span>{UnreadCount}</x.span>
        </x.span>
      }
    </StyledLink>
  )
}

export default NotificationsBell
