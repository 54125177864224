import React, { memo, useCallback } from 'react'
import { x } from '@xstyled/styled-components'
import StyledLink from '../components/ui/Link'
import cssfxUnderline from '../components/ui/cssfxUnderline'
import Menu from './ui/Menu'
import { Drawer, useDrawer } from '../components/ui/Drawer'
import {Accordion, AccordionItem} from '../components/ui/CSSAccordion'
import { SearchBar } from './MobileSearchBar'

import menuFB from '../../../assets/images/social-media/facebook-menu.svg'
import menuT from '../../../assets/images/social-media/x.svg'
import menuI from '../../../assets/images/social-media/instagram-menu.svg'
import menuL from '../../../assets/images/social-media/linkedin-menu.svg'
import menuY from '../../../assets/images/social-media/youtube-menu.svg'
import menuRSS from '../../../assets/images/social-media/rss-menu.svg'

import menu from '../../../assets/images/icons/menu-ico.svg'
import closeBtn from '../../../assets/images/icons/close-btn.svg'
import searchGry from '../../../assets/images/icons/search-gry-ico.svg'
import arrowLeftLtGrey from '../../../assets/images/icons/arrow-left-ltgrey.svg'

const HamburgerNav = ({ prefix, toggleScrolling, setIsNavOpen, sessionId, visitorId, section }) => {
  const drawer = useDrawer()
  const clickSource = 'Hamburger Nav'
  const MenuLink = (props) => {
    return (<StyledLink to={props.to} text='menu-sub-head' p={5} bg={{ hover: 'ltGrey' }} color={{ hover: 'red' }} display='block' onClick={() => { drawer.close(); toggleScrolling() }} visitorId={props.visitorId} sessionId={props.sessionId} source='Hamburger Nav' section={props.section}>{props.children}</StyledLink>)
  }
  const openHandler = useCallback(() => {
    drawer.open()
    toggleScrolling()
    if (setIsNavOpen) {
      setIsNavOpen(true)
    }
  })
  const closeHandler = useCallback(() => {
    drawer.close()
    toggleScrolling()
    if (setIsNavOpen) {
      setIsNavOpen(false)
    }
  })
  return (
    <>
      <x.button onClick={openHandler} backgroundColor='white' color='grey' border='0' p={4} display='flex' justifyContent='center' alignItems='center'>
        <img src={menu} alt='' />
      </x.button>
      <Drawer {...drawer} placement='right' width='100%'>
        <x.button onClick={closeHandler} backgroundColor='white' p={10} top={0} right={0} display='flex' alignItems='center' justifyContent='flex-end' w={'100%'}>
          <x.img src={closeBtn} />
        </x.button>
        <SearchBar backGroundColor='#F5F5F5'/>
        <x.nav pb={73}>
          <Accordion>
            <StyledLink to='/' display='flex' alignItems='center' py={20} ml={10} color={{ _: '#BBB', hover: 'red' }} textTransform='uppercase' text='xsmall' visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource} onClick={() => { drawer.close(); toggleScrolling() }}><x.img src={arrowLeftLtGrey} />Home Page</StyledLink>
            <AccordionItem prefix={prefix} title='News' name={'nav-menu'}>
              <x.ul>
                <x.li mb={10}><MenuLink to='/news' visitorId={visitorId} sessionId={sessionId} section={section}>All News</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/south-africa' visitorId={visitorId} sessionId={sessionId} section={section}>South Africa</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/politics' visitorId={visitorId} sessionId={sessionId} section={section}>Politics</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/crime-and-courts' visitorId={visitorId} sessionId={sessionId} section={section}>Crime and Courts</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/world' visitorId={visitorId} sessionId={sessionId} section={section}>World</MenuLink></x.li>
                <x.li my={10}><MenuLink to='/news/africa' visitorId={visitorId} sessionId={sessionId} section={section}>Africa</MenuLink></x.li>
                <x.li my={10}><MenuLink to='/business-report/brics' visitorId={visitorId} sessionId={sessionId} section={section}>BRICS</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/education' visitorId={visitorId} sessionId={sessionId} section={section}>Education</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/environment' visitorId={visitorId} sessionId={sessionId} section={section}>Environment</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/weather' visitorId={visitorId} sessionId={sessionId} section={section}>Weather</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/traffic' visitorId={visitorId} sessionId={sessionId} section={section}>Traffic</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/news/feel-good' visitorId={visitorId} sessionId={sessionId} section={section}>Good News</MenuLink></x.li>
                <x.li><MenuLink to='/news/energy' visitorId={visitorId} sessionId={sessionId} section={section}>Energy</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Opinion' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/opinion' visitorId={visitorId} sessionId={sessionId} section={section}>All Opinion</MenuLink></x.li>
                <x.li><MenuLink to='/opinion/letters' visitorId={visitorId} sessionId={sessionId} section={section}>Letters</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Business' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/business' visitorId={visitorId} sessionId={sessionId} section={section}>All Business</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/business-report' visitorId={visitorId} sessionId={sessionId} section={section}>Business Report</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/personal-finance' visitorId={visitorId} sessionId={sessionId} section={section}>Personal Finance</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/property' visitorId={visitorId} sessionId={sessionId} section={section}>Property</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/technology' visitorId={visitorId} sessionId={sessionId} section={section}>Technology</MenuLink></x.li>
                <x.li><MenuLink to='/business/jobs' visitorId={visitorId} sessionId={sessionId} section={section}>Jobs</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Lifestyle' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/lifestyle' visitorId={visitorId} sessionId={sessionId} section={section}>All Lifestyle</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/lifestyle/food-and-restaurants' visitorId={visitorId} sessionId={sessionId} section={section}>Food & Restaurants</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/lifestyle/health' visitorId={visitorId} sessionId={sessionId} section={section}>Health</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/lifestyle/style-beauty' visitorId={visitorId} sessionId={sessionId} section={section}>Style & Beauty</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/lifestyle/family' visitorId={visitorId} sessionId={sessionId} section={section}>Relationships</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/lifestyle/competitions' visitorId={visitorId} sessionId={sessionId} section={section}>Competitions</MenuLink></x.li>
                <x.li><MenuLink to='/lifestyle/shopping' visitorId={visitorId} sessionId={sessionId} section={section}>Shopping</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Entertainment' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/entertainment' visitorId={visitorId} sessionId={sessionId} section={section}>All Entertainment</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/music' visitorId={visitorId} sessionId={sessionId} section={section}>Music</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/movies' visitorId={visitorId} sessionId={sessionId} section={section}>Movies</MenuLink></x.li>
                <x.li my={10}><MenuLink to='/entertainment/streaming' visitorId={visitorId} sessionId={sessionId} section={section}>Streaming</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/celebrity-news' visitorId={visitorId} sessionId={sessionId} section={section}>Celebrity</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='entertainment/events' visitorId={visitorId} sessionId={sessionId} section={section}>Events</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/whats-on' visitorId={visitorId} sessionId={sessionId} section={section}>What's On</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/books' visitorId={visitorId} sessionId={sessionId} section={section}>Books</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/entertainment/royals' visitorId={visitorId} sessionId={sessionId} section={section}>Royals</MenuLink></x.li>
                <x.li><MenuLink to='/entertainment/tv' visitorId={visitorId} sessionId={sessionId} section={section}>TV</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Sport' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/sport' visitorId={visitorId} sessionId={sessionId} section={section}>All Sport</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/tags/paralympics-2024' visitorId={visitorId} sessionId={sessionId} section={section}>Paralympics 2024</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/sport/soccer' visitorId={visitorId} sessionId={sessionId} section={section}>Soccer</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/sport/soccer/psl' visitorId={visitorId} sessionId={sessionId} section={section}>Soccer PSL</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/sport/rugby' visitorId={visitorId} sessionId={sessionId} section={section}>Rugby</MenuLink></x.li>
                <x.li my={10}><MenuLink to='/sport/cricket' visitorId={visitorId} sessionId={sessionId} section={section}>Cricket</MenuLink></x.li>
                <x.li my={10}><MenuLink to='/sport/betting' visitorId={visitorId} sessionId={sessionId} section={section}>Betting</MenuLink></x.li>
                <x.li><MenuLink to='/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <StyledLink to='/travel' erRadius={3} p='10px 15px' mb={5} bg='#F5F5F5' display='block' fontWeight='600' color='#434343' onClick={() => { drawer.close(); toggleScrolling() }} visitorId={visitorId} sessionId={sessionId} section={section}>Travel</StyledLink>
            {/* <StyledLink to='/video' borderRadius={3} p='10px 15px' mb={5} bg='#F5F5F5' display='block' fontWeight='600' color='#434343' onClick={() => {drawer.close(); toggleScrolling()}} visitorId={visitorId} sessionId={sessionId} section={section}>Video</StyledLink> */}
            <AccordionItem prefix={prefix} title='More' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/motoring' visitorId={visitorId} sessionId={sessionId} section={section}>Motoring</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/technology' visitorId={visitorId} sessionId={sessionId} section={section}>Technology</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/property' visitorId={visitorId} sessionId={sessionId} section={section}>Property</MenuLink></x.li>
                <x.li><MenuLink to='/news/education' visitorId={visitorId} sessionId={sessionId} section={section}>Education</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='Newspapers' name='nav-menu'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/the-star' visitorId={visitorId} sessionId={sessionId} section={section}>The Star</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/capeargus' visitorId={visitorId} sessionId={sessionId} section={section}>Cape Argus</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/weekend-argus' visitorId={visitorId} sessionId={sessionId} section={section}>Weekend Argus</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/dailynews' visitorId={visitorId} sessionId={sessionId} section={section}>Daily News</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/capetimes' visitorId={visitorId} sessionId={sessionId} section={section}>Cape Times</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/mercury' visitorId={visitorId} sessionId={sessionId} section={section}>The Mercury</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/pretoria-news' visitorId={visitorId} sessionId={sessionId} section={section}>Pretoria News</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/saturday-star' visitorId={visitorId} sessionId={sessionId} section={section}>Saturday Star</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/ios' visitorId={visitorId} sessionId={sessionId} section={section}>The Independent on Saturday</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/sunday-tribune' visitorId={visitorId} sessionId={sessionId} section={section}>Sunday Tribune</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/sundayindependent' visitorId={visitorId} sessionId={sessionId} section={section}>Sunday Independent</MenuLink></x.li>
                <x.li mb={10}><x.a href='https://www.dfa.co.za/' target='_blank' text='menu-sub-head' p={5} bg={{ hover: 'ltGrey' }} color={{ hover: 'red' }} display='block'>DFA</x.a></x.li>
                <x.li mb={10}><MenuLink to='/thepost' visitorId={visitorId} sessionId={sessionId} section={section}>The Post</MenuLink></x.li>
                <x.li mb={10}><x.a href='https://www.dailyvoice.co.za/' target='_blank' text='menu-sub-head' p={5} bg={{ hover: 'ltGrey' }} color={{ hover: 'red' }} display='block'>Daily Voice</x.a></x.li>
                <x.li mb={10}><x.a href='https://www.isolezwe.co.za/' target='_blank' text='menu-sub-head' p={5} bg={{ hover: 'ltGrey' }} color={{ hover: 'red' }} display='block'>Isolezwe</x.a></x.li>
                <x.li mb={10}><MenuLink to='/business-report' visitorId={visitorId} sessionId={sessionId} section={section}>Business Report</MenuLink></x.li>
                <x.li><MenuLink to='/personal-finance' visitorId={visitorId} sessionId={sessionId} section={section}>Personal Finance</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
            <AccordionItem prefix={prefix} title='About Us'>
              <x.ul>
                <x.li mb={10}><MenuLink to='/newspapers' visitorId={visitorId} sessionId={sessionId} section={section}>Subscribe to Independent Media</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/newsletters' visitorId={visitorId} sessionId={sessionId} section={section}>Subscribe to Email Newsletter</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/about-iol' visitorId={visitorId} sessionId={sessionId} section={section}>About IOL</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/contact-us' visitorId={visitorId} sessionId={sessionId} section={section}>Contact Us</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/press-code' visitorId={visitorId} sessionId={sessionId} section={section}>Press Code</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/privacy-policy' visitorId={visitorId} sessionId={sessionId} section={section}>Privacy Policy</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/terms-and-conditions' visitorId={visitorId} sessionId={sessionId} section={section}>Terms &amp; Conditions</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/advertising' visitorId={visitorId} sessionId={sessionId} section={section}>Advertise with us</MenuLink></x.li>
                <x.li mb={10}><MenuLink to='/feedback' visitorId={visitorId} sessionId={sessionId} section={section}>Feedback</MenuLink></x.li>
                <x.li><MenuLink to='/complaints' visitorId={visitorId} sessionId={sessionId} section={section}>Complaints Procedure</MenuLink></x.li>
              </x.ul>
            </AccordionItem>
          </Accordion>
          <x.div display='flex' alignItems='center' py={20} ml={10} color='#BBB' textTransform='uppercase' text='xsmall'>Social Media</x.div>
          <x.a href='https://www.facebook.com/IOLnews' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuFB} mr={10} /> Facebook</x.a>
          <x.a href='https://www.twitter.com/IOL' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuT} mr={10} h='24px' /> Twitter</x.a>
          <x.a href='https://www.instagram.com/iolnews' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuI} mr={10} /> Instagram</x.a>
          <x.a href='https://www.linkedin.com/company/iol-news/' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuL} mr={10} /> LinkedIn</x.a>
          <x.a href='https://www.youtube.com/channel/UCeQFd7S6urAw6mm0kS4xuhg' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuY} mr={10} /> Youtube</x.a>
          <x.a href='/rss' display='flex' alignItems='center' backgroundColor='#F5F5F5' mb={5} p='10px 15px' borderRadius={3} text='dropdown'><x.img src={menuRSS} mr={10} /> RSS Feeds</x.a>
        </x.nav>
      </Drawer>
    </>
  )
}

export default memo(HamburgerNav)
