import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setSubscription } from '../store/app'
import { Workbox } from 'workbox-window'

let _registration = false

export function urlB64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const ServiceWorkerNotifications = ({ wb, ...props }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      wb = new Workbox('/sw.js')
      console.log('Workbox initialised')
      wb.addEventListener('activated', (event) => {
        console.log('ServiceWorker: (activated) ServiceWorker activated', event)
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        if (!event.isUpdate) {
          console.log('This site is now available offline')
          // this.setState({
          //   wbMessage: 'This site is now available offline'
          // })
          // If your service worker is configured to precache assets, those
          // assets should all be available now.
        } else {
          console.log('ServiceWorker: (activated) This site has been updated')
        }
      })
      wb.addEventListener('message', (event) => {
        if (event.data.type === 'CACHE_UPDATED') {
          const { updatedURL } = event.data.payload
          console.log(`ServiceWorker: (message) A newer version of ${updatedURL} is available!`)
          // this.setState({
          //   wbMessage: `A newer version of ${updatedURL} is available!`
          // })
        }
        // if (event.data.type === 'NAVIGATE') {
        //   const updatedURL = event.data.payload
        //   props.history.push(updatedURL)
        // }
      })
      wb.addEventListener('waiting', (event) => {
        console.log('ServiceWorker: (waiting) A newer version of this site is available! Please reload your page to activate it.')
      })

      wb.register().then(registration => {
        _registration = registration
        props.setSubscription(false)
      })
    }
  }, [])
  return null
}

export function getRegistration () {
  return _registration
}

let wb

const ServiceWorker = (props) => {
  return (
    <ServiceWorkerNotifications wb={wb} {...props} />
  )
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ setSubscription }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceWorker)
