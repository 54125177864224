import React from 'react'
import { useLocation } from 'react-router-dom'
import styled, { x } from '@xstyled/styled-components'
import { errorPageView } from '../../store/app'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useCookie } from '../components/ui/utils'

const ReloadButton = styled.button`
  text-transform: uppercase;
  padding: 10px 20px;
  color: #FFF;
  background-color: grey;
  transition: all linear .3s;
  &:hover {
    background-color: black;
    color: #FFF;
  }`



const ErrorBoundaryErrorPage = (props) => {
  const location = useLocation()
  const [errorRetry, setErrorRetry] = useCookie('errorRetry', 0)

  React.useEffect(()=>{
    props.errorPageView(location.pathname)
  }, [])

  if (parseInt(errorRetry) < 2) {
    setErrorRetry(parseInt(errorRetry) + 1, {seconds: 15})
    window.location.reload(true)
    return null;
  }
  return (
    <>
      <x.main p={20} mt={{ sm: '96px', lg: '173px' }} w='100%' maxWidth={500} mx='auto' display='flex' justifyContent='center' alignItems='center' flexDirection='column' h='100vh'>
        <x.h1 text='h1' pb={20}>OOPS!</x.h1>
        <x.h3 text='h4' pb={10} mb={20}>Something went wrong</x.h3>
        <x.p text='p' mb={20} textAlign='center'>{props.error.toString()}</x.p>
        <ReloadButton onClick={() => {
          return window.location.reload()
        }}
        >Reload Page
        </ReloadButton>
      </x.main>
    </>
  )
}

const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => bindActionCreators({ errorPageView }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryErrorPage)
