import { useRef, useState, useEffect } from 'react'

function throttle (fn, cooldown) {
  let lastArgs
  const run = () => {
    if (lastArgs) {
      fn(...lastArgs)
      lastArgs = undefined
    }
  }
  const throttled = (...args) => {
    const isOnCooldown = !!lastArgs
    lastArgs = args
    if (isOnCooldown) {
      return
    }
    window.setTimeout(run, cooldown)
  }
  return throttled
}

function useScrollDirection (options) {
  if (typeof window === 'undefined') {
    return 'up'
  }
  const topDelay = options.topDelay || 0
  const scrollPos = useRef(0)
  const [direction, setDirection] = useState('up')

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.pageYOffset > topDelay) {
        setDirection(window.pageYOffset > scrollPos.current ? 'down' : 'up')
        if (Math.abs(window.pageYOffset - scrollPos.current) > 600) {
          scrollPos.current = window.pageYOffset
        }
      } else {
        setDirection('up')
      }
    }, 100)
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])
  return direction
}

export default useScrollDirection
