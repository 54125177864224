// import './wdyr'
import './client-cache'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { Provider as ReaKitProvider } from 'reakit'
import { loadableReady } from '@loadable/component'
import ScrollMemory from 'react-router-scroll-memory'

import App, { ErrorBoundary as AppErrorBoundary } from '../app/App'
import ServiceWorker from '../app/ServiceWorker'
import createStore from '../store/createStore'
import rootSaga from '../store/sagas'
import { updateConnectivity } from '../store/app'

function getVisitorId () {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith('visitorId='))
    .split('=')[1]
}
function getVisitorSessionId () {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith('sessionId='))
    .split('=')[1]
}
const store = createStore({
  ...window.__PRELOADED_STATE__,
  ...{
    user: {
      visitorId: getVisitorId(),
      sessionId: getVisitorSessionId()
    }
  }
})

store.runSaga(rootSaga)

window.addEventListener('offline', () => {
  store.dispatch(updateConnectivity(false))
})

window.addEventListener('online', () => {
  store.dispatch(updateConnectivity(true))
})

window.startPath = window.location.pathname
// const isProd = process.env.NODE_ENV === 'production'
loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <ReaKitProvider>
        <BrowserRouter>
          <ServiceWorker />
          <ScrollMemory />
          <AppErrorBoundary>
            <App />
          </AppErrorBoundary>
        </BrowserRouter>
      </ReaKitProvider>
    </Provider>,
    document.getElementById('root')
  )
})

if (module.hot) {
  module.hot.accept()
}
