import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect, withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'
import { ThemeProvider, Preflight } from '@xstyled/styled-components'
import {
  enableSingleRequest, enableLazyLoad, enableMappings, disableMappings, enableInterstitial, enableAgeTarget, enableArticleLevelTarget,
  enableGenderTarget, disableAgeTarget, disableArticleLevelTarget, disableGenderTarget, enableSectionLevelTarget, disableSectionLevelTarget
} from 'ion-ads'
// import Ad from './components/Ad'
// import { FORMATS } from './components/AdFormats'

import Theme from '../theme/default'
import GlobalStyle from '../theme/GlobalStyle'
import { pageView } from '../store/app'
import { getVisitorId, getSessionId } from '../store/article'
import useHasMounted from '../lib/useHasMounted'

import logo from '../../assets/images/general/iol-logo.svg'
import ScrollToTop from './components/ui/ScrollToTop'
import ErrorBoundaryErrorPage from './pages/ErrorBoundaryErrorPage'
import PushPrompt from './components/PushPrompt'

import Header from './shell/Header'
import Footer from './shell/Footer'

import { HomepageSkeleton, AuthorPageSkeleton, BasicPageSkeleton, TrendsPageSkeleton, ArticlePageSkeleton, BlankPageSkeleton } from './components/Skeletons'
import { MobileNewsletterSubscribePopup } from './components/MobileNewsletterSubscribePopup'

const Home = loadable(() => import('./pages/Home'), {
  fallback: <HomepageSkeleton />
})
const DefaultSection = loadable(() => import('./components/DefaultSection'), {
  fallback: <BlankPageSkeleton />
})
const Article = loadable(() => import('./pages/Article'), {
  fallback: <ArticlePageSkeleton />
})
// const ArticleMock = loadable(() => import('./pages/ArticleMock'))
const MyNewsPage = loadable(() => import('./pages/MyNewsPage'))
const StaticPage = loadable(() => import('./pages/StaticPage'))
const SearchPage = loadable(() => import('./pages/SearchPage'), {
  fallback: <BasicPageSkeleton />
})
const CookieForm = process.env.RAZZLE_ENABLE_QUANTCAST ? undefined : loadable(() => import('./components/CookieForm'))
const AuthorSectionPage = loadable(() => import('./pages/AuthorPage'), {
  fallback: <AuthorPageSkeleton />
})
const VideoPage = loadable(() => import('./pages/VideoPage'))
const BrandstoriesPage = loadable(() => import('./pages/BrandstoriesPage'))
const TrendsPage = loadable(() => import('./pages/TrendsPage'), {
  fallback: <TrendsPageSkeleton />
})
const Feedback = loadable(() => import('./pages/Feedback'))
const NewslettersPage = loadable(() => import('./pages/NewslettersPage'))
const Digiserts = loadable(() => import('./pages/DigisertsPage'))
const NewspapersPage = loadable(() => import('./pages/NewspapersPage'))
const AuthorsPage = loadable(() => import('./pages/AuthorsPage'))
const BookmarksPage = loadable(() => import('./pages/BookmarksPage'))
const PushNotificationsManagementPage = loadable(() => import('./pages/PushNotificationsManagementPage'))

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <ErrorBoundaryErrorPage error={this.state.error} />
        </>
      )
    }
    return this.props.children
  }
}

export const App = (props) => {
  const [scrolling, setScrolling] = useState(true)
  const toggleScrolling = () => setScrolling(!scrolling)
  const isHydrated = useHasMounted()
  useEffect(() => {
    if (isHydrated) {
      props.pageView(props.location.pathname, props.visitorId)
    }
  }, [props.location.pathname])
  return (
    <ThemeProvider theme={Theme}>
      <Preflight />
      <GlobalStyle />
      <Helmet defaultTitle={process.env.RAZZLE_SITE_TITLE}>
        <html lang='en' />
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
        <meta property='fb:app_id' content={process.env.RAZZLE_SITE_FACEBOOK_APP_ID} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
        <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta property='og:image' itemprop='image' content={logo} />
        <meta itemprop='inLanguage' content='en' />
        <meta itemprop='image' content={logo} />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='google-site-verification' content={process.env.RAZZLE_GOOGLE_SITE_VERIFICATION} />
        <meta itemprop='image' content={logo} />
        <meta itemprop='thumbnailUrl' content={logo} />
        <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER_USER} />
        <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image' content={logo} />
        <meta property='fb:pages' content='21993963624' />
        <body className={scrolling ? '' : 'scrollingDisabled'} />
      </Helmet>
      <ScrollToTop />
      <Header {...props} toggleScrolling={toggleScrolling} />
      <Switch>
        <Route exact path='/feedback/thankyou' render={() => <Feedback thanksMessage='Thank you for your feedback. We will get back to you shortly.' />} />
        <Route exact path='/feedback' component={Feedback} />
        <Route exact path='/' render={p => <Home {...p} />} />
        <Route exact path='/test' render={p => <Home adPath='/test' {...p} />} />
        <Route exact path='/test1' render={p => <Home adPath='/test1' {...p} />} />
        <Route exact path='/test2' render={p => <Home adPath='/test2' {...p} />} />
        {/* <Route exact path='/article' component={ArticleMock} /> */}
        <Route exact path='/offline' render={() => <div>Loading...</div>} />
        <Route exact path='/newsletters' render={p => <NewslettersPage {...p} />} />
        <Route path='/digiserts' render={p => <Digiserts {...p} />} />
        <Route
          path='/preview' render={p => {
            const searchParams = new URLSearchParams(p.location.search)
            const shareId = searchParams.get('share_id')
            return <Article {...props} {...p} url='preview' contentKey={shareId} />
          }}
        />
        <Route
          exact path='/search' render={(p) => {
            return (
              <SearchPage
                {...p}
                query={p.location.search}
                twitterName={process.env.RAZZLE_SITE_TWITTER_USER}
                titleTemplate={'%s | ' + process.env.RAZZLE_SITE_TITLE}
              />
            )
          }}
        />
        <Route
          exact path='/:page(terms-and-conditions)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Terms and Conditions | IOL'
              description='Anyone making use of this website is subject to the following Terms and Conditions. Read more on our Terms of Use.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(privacy-policy)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Privacy Policy | IOL '
              description='We take the privacy of our users seriously. Independent Online subscribes to and complies with the following privacy policy.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(about-iol)' render={p =>
            <StaticPage
              page='static-pages/about-iol-pwa'
              title='About Us and Independent Media | IOL'
              description='Find out more about Independent Online and Independent Media. Read the answers to frequently asked questions from our readers and advertisers.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(contact-us)' render={p =>
            <StaticPage
              page='static-pages/contact-iol'
              title='About Us and Independent Media | IOL'
              description='Find out more about Independent Online and Independent Media. Read the answers to frequently asked questions from our readers and advertisers.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(advertising)' render={p =>
            <StaticPage
              page='static-pages/advertising-iol'
              title='About Us and Independent Media | IOL'
              description='Find out more about Independent Online and Independent Media. Read the answers to frequently asked questions from our readers and advertisers.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(newspapers-contacts)' render={p =>
            <StaticPage
              page='newspapers-community-news/newspapers'
              title='Explore Independent Media`s Premium Newspaper Titles | IOL'
              description='Find out more about Independent Media`s newspaper titles, including The Star, Cape Times, The Mercury, Cape Argus, Daily News and Pretoria News, among others.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(augmented-reality)' render={p =>
            <StaticPage
              page='static-pages/augmented-reality'
              title='Augmented Reality | IOL'
              description='Augmented Reality.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(iol-mobile-app-faq)' render={p =>
            <StaticPage
              page='static-pages/iol-mobile-app-faq'
              title='Augmented Reality | IOL'
              description='Augmented Reality.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(complaints)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Independent Media Complaints Procedure | IOL'
              description='Read more about Independent Media`s complaints procedure and how you can submit a complaint through the Ombudsman.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(help)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Help on IOL'
              description='Help pages for navigating your way around the IOL site.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(press-code)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Independent Media Press Code | IOL'
              description='IOL is committed to serving the interests of our readers and subscribes to the following Press Code of conduct for South African print and online media.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(classifieds)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Classified Adverts | IOL'
              description='Looking to sell goods or services? Find out how to place your Classified Advert in any of our titles nationally, by contacting an agent in your region.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(cape-community-newspapers)' render={p =>
            <StaticPage
              page={'newspapers-community-news/' + p.match.params.page}
              title='Local News and Sports Updates from Cape Community Newspapers | IOL'
              description='Read the latest news from your neighbourhood. Independent Media`s community newspapers in Cape Town covers news on local issues, personalities and sport.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(rss)' render={p =>
            <StaticPage
              page={'static-pages/' + p.match.params.page}
              title='Subscribe to our RSS Feeds | IOL'
              description='Subscribe to our RSS (Really Simple Syndication) feeds to gain access to our content across multiple platforms and devices with regular automated updates.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(business-report/market-indicators)' render={p =>
            <StaticPage
              page='static-pages/business-report-market-indicators'
              title='Latest and Breaking Market Data News | Business Report'
              sectionLabel='Market Indicators'
              description='Get all the latest market data news, anything from the rand to dollar exchange rate, to mineral and oil updates as well as share price updates that take place on the JSE.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(business-report/about-us)' render={p =>
            <StaticPage
              page='static-pages/business-report-about-us'
              title='About Us | Business Report'
              description='We are determined to give you the latest business and technology news in South Africa and globally. We bring you up-to-date finance news, across all sectors.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(business-report/contact-us)' render={p =>
            <StaticPage
              page='static-pages/business-report-contact-us'
              title='Contact Us | Get in Touch | Business Report'
              description='We would love to hear from you. Get in touch with the Business Report Team by emailing us on BR.online@inl.co.za.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/about-us)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-about-us'
              title='About Us | Personal Finance'
              description='Get to know more about us. Personal Finance helps you secure your financial independence by exploring topics on wealth, investment and prosperity.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/contact-us)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-contact-us'
              title='Contact Us | Personal Finance'
              description='We would like to hear from you. Find out how you can get on touch with the Personal Finance team based at Newspaper House, 122 St George`s Mall, Cape Town.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/collective-investments)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-collective-investments'
              title='Collective Investments | IOL Personal Finance'
              sectionLabel='Collective Investments Results'
              description='Personal Finance brings you the latest reports and updates on collective investments performance.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/fund-look-up)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-fund-look-up'
              title='Fund Look Up | IOL Personal Finance'
              sectionLabel='Fund Look Up'
              description='Use Personal Finance`s fund look-up tool for updates on various asset Fund Manager`s performances.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/fund-ranking-tool)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-fund-ranking-tool'
              title='Fund Ranking Tool | IOL Personal Finance'
              sectionLabel='Fund Ranking Tool'
              description='Use Personal Finance`s fund ranking tool to see which investment and asset management company ranks the highest.'
              {...p}
            />}
        />
        <Route
          exact path='/:page(personal-finance/performance-comparison-tool)' render={p =>
            <StaticPage
              page='static-pages/personal-finance-performance-comparison-tool'
              title='Performance Comparison Tool | IOL Personal Finance'
              sectionLabel='Performance Comparison Tool'
              description='Use Personal Finance`s performance comparison tool to compare investment performance (returns) over different periods and/or different methods in a single table.'
              {...p}
            />}
        />
        {/* <Route exact path='/:page(personal-finance/money-tools)' render={p =>
      <StaticPage
        page='static-pages/personal-finance-money-tools'
        title='Money Tools | IOL Personal Finance'
        description='Personal Finance Money tools'
        {...p}
      />} />
    <Route exact path='/:page(personal-finance/money-tools-loans)' render={p =>
      <StaticPage
        page='static-pages/personal-finance-money-tools-loans'
        title='Loan Calculator | IOL Personal Finance'
        description='Loan Calculator'
        {...p}
      />} />
    <Route exact path='/:page(personal-finance/money-tools/interest-on-savings)' render={p =>
      <StaticPage
        page='static-pages/personal-finance-money-tools-interest-on-savings'
        title='Interest on Savings Calculator | IOL Personal Finance'
        description='Interest on Savings Calculator'
        {...p}
      />} /> */}
        <Route
          exact path='/:page(the-star/about-us)' render={p =>
            <StaticPage
              page='static-pages/the-star-about-us'
              title='The Star - About Us'
              sectionLabel='The Star - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(the-star/contact-us)' render={p =>
            <StaticPage
              page='static-pages/the-star-contact-us'
              title='The Star - Contact Us'
              sectionLabel='The Star - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(the-star/advertising)' render={p =>
            <StaticPage
              page='static-pages/the-star-advertising'
              title='The Star - Advertising'
              sectionLabel='The Star - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capeargus/about-us)' render={p =>
            <StaticPage
              page='static-pages/capeargus-about-us'
              title='Cape Argus - About Us'
              sectionLabel='Cape Argus - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capeargus/contact-us)' render={p =>
            <StaticPage
              page='static-pages/capeargus-contact-us'
              title='Cape Argus - Contact Us'
              sectionLabel='Cape Argus - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capeargus/advertising-and-readership)' render={p =>
            <StaticPage
              page='static-pages/capeargus-advertising-and-readership'
              title='Cape Argus - Advertising'
              sectionLabel='Cape Argus - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(dailynews/about-us)' render={p =>
            <StaticPage
              page='static-pages/dailynews-about-us'
              title='Daily News - About Us'
              sectionLabel='Daily News - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(dailynews/contact-us)' render={p =>
            <StaticPage
              page='static-pages/dailynews-contact-us'
              title='Daily News - Contact Us'
              sectionLabel='Daily News - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(dailynews/advertising-and-readership)' render={p =>
            <StaticPage
              page='static-pages/dailynews-advertising-and-readership'
              title='Daily News - Advertising'
              sectionLabel='Daily News - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capetimes/about-us)' render={p =>
            <StaticPage
              page='static-pages/capetimes-about-us'
              title='Cape Times - About Us'
              sectionLabel='Cape Times - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capetimes/contact-us)' render={p =>
            <StaticPage
              page='static-pages/capetimes-contact-us'
              title='Cape Times - Contact Us'
              sectionLabel='Cape Times - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(capetimes/advertising-and-readership)' render={p =>
            <StaticPage
              page='static-pages/capetimes-advertising-and-readership'
              title='Cape Times - Advertising'
              sectionLabel='Cape Times - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(mercury/about-us)' render={p =>
            <StaticPage
              page='static-pages/mercury-about-us'
              title='The Mercury - About Us'
              sectionLabel='The Mercury - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(mercury/contact-us)' render={p =>
            <StaticPage
              page='static-pages/mercury-contact-us'
              title='The Mercury - Contact Us'
              sectionLabel='The Mercury - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(mercury/advertising-and-readership)' render={p =>
            <StaticPage
              page='static-pages/mercury-advertising-and-readership'
              title='The Mercury - Advertising'
              sectionLabel='The Mercury - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(pretoria-news/about-us)' render={p =>
            <StaticPage
              page='static-pages/pretoria-news-about-us'
              title='Pretoria News - About Us'
              sectionLabel='Pretoria News - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(pretoria-news/contact-us)' render={p =>
            <StaticPage
              page='static-pages/pretoria-news-contact-us'
              title='Pretoria News - Contact Us'
              sectionLabel='Pretoria News - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(pretoria-news/advertising)' render={p =>
            <StaticPage
              page='static-pages/pretoria-news-advertising'
              title='Pretoria News - Advertising'
              sectionLabel='Pretoria News - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(saturday-star/advertising)' render={p =>
            <StaticPage
              page='static-pages/saturday-star-advertising'
              title='Saturday Star - Advertising'
              sectionLabel='Saturday Star - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(ios/about-us)' render={p =>
            <StaticPage
              page='static-pages/ios-about-us'
              title='The Independent on Saturday - About Us'
              sectionLabel='The Independent on Saturday - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(ios/contact-us)' render={p =>
            <StaticPage
              page='static-pages/ios-contact-us'
              title='The Independent on Saturday - Contact Us'
              sectionLabel='The Independent on Saturday - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(ios/advertising)' render={p =>
            <StaticPage
              page='static-pages/ios-advertising'
              title='The Independent on Saturday - Advertising'
              sectionLabel='The Independent on Saturday - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sunday-tribune/about-us)' render={p =>
            <StaticPage
              page='static-pages/sunday-tribune-about-us'
              title='Sunday Tribune - About Us'
              sectionLabel='Sunday Tribune - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sunday-tribune/contact-us)' render={p =>
            <StaticPage
              page='static-pages/sunday-tribune-contact-us'
              title='Sunday Tribune - Contact Us'
              sectionLabel='Sunday Tribune - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sunday-tribune/advertising)' render={p =>
            <StaticPage
              page='static-pages/sunday-tribune-advertising'
              title='Sunday Tribune - Advertising'
              sectionLabel='Sunday Tribune - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sundayindependent/about-us)' render={p =>
            <StaticPage
              page='static-pages/sundayindependent-about-us'
              title='Sunday Independent - About Us'
              sectionLabel='Sunday Independent - About Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sundayindependent/contact-us)' render={p =>
            <StaticPage
              page='static-pages/sundayindependent-contact-us'
              title='Sunday Independent - Contact Us'
              sectionLabel='Sunday Independent - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(sundayindependent/advertising)' render={p =>
            <StaticPage
              page='static-pages/sundayindependent-advertising'
              title='Sunday Independent - Advertising'
              sectionLabel='Sunday Independent - Advertising'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(thepost/contact-us)' render={p =>
            <StaticPage
              page='static-pages/thepost-contact-us'
              title='The Post - Contact Us'
              sectionLabel='The Post - Contact Us'
              description=''
              {...p}
            />}
        />
        <Route
          exact path='/:page(thepost/advertising-and-readership)' render={p =>
            <StaticPage
              page='static-pages/thepost-advertising-and-readership'
              title='The Post - Advertising'
              sectionLabel='The Post - Advertising'
              description=''
              {...p}
            />}
        />
        {/* <Route
          exact path='/video' render={() => (
            <VideoPage
              {...props}
              section='multimedia/videos'
              sectionLabel='Video'
              relatedSections={[{ id: 'multimedia/podcasts' }, { id: 'news/video', label: 'News' }, { id: 'lifestyle/video', label: 'Lifestyle' }, { id: 'sport/video', label: 'Sport' }, { id: 'news/politics/video', label: 'Politics' }, { id: 'business-report/video', label: 'Business Report' }]}
              title='Watch the Latest Video Content | IOL'
              description='Watch the latest news, sport, business, entertainment, lifestyle, travel and motoring videos on IOL.'
            />
          )}
        /> */}
        <Route
          exact path='/brandstories' render={() => (
            <BrandstoriesPage
              {...props}
              section='brandstories'
              mostReadSection='news'
              title=''
              description=''
            />
          )}
        />
        <Route
          exact path='/trends' render={() => (
            <TrendsPage
              {...props}
              section='trends'
              mostReadSection='news'
              title='IOL Trends'
              description='All trends that have been offered on IOL to date.'
            />
          )}
        />
        <Route
          exact path='/my-notifications' render={() => (
            <PushNotificationsManagementPage
              {...props}
              section='news'
              mostReadSection='news'
              title='Notifications Management'
              description='View your notifications history and manage the entities that you follow.'
            />
          )}
        />
        <Route
          exact path='/my-news' render={() => (
            <MyNewsPage
              {...props}
              section='news'
              mostReadSection='news'
              title='My News'
              description='Articles chosen for the user based on historic preferences.'
            />
          )}
        />
        <Route
          exact path='/my-bookmarks' render={() => (
            <BookmarksPage
              {...props}
              section='news'
              mostReadSection='news'
              title='Bookmarks'
              description='Articles which the user has bookmarked for offline reading.'
            />
          )}
        />
        <Route
          exact path='/newspapers' render={() => (
            <NewspapersPage
              {...props}
              section='newspapers'
              title='Explore Independent Media`s Premium Newspaper Titles | IOL'
              description='Find out more about Independent Media`s newspaper titles, including The Star, Cape Times, The Mercury, Cape Argus, Daily News and Pretoria News, among others.'
            />
          )}
        />
        <Route
          exact path='/authors' render={() => (
            <AuthorsPage
              {...props}
              section='authors'
              mostReadSection='news'
              title='IOL Authors'
              description='All authors on IOL.'
            />
          )}
        />
        <Route
          path='/authors/:author([a-zA-Z-]+)' render={(p) =>
            <AuthorSectionPage {...props} {...p} authorSlug={p.match.params.author} />}
        />
        <Route
          exact path='/puzzles' render={p => (
            <StaticPage
              page='static-pages/puzzles'
              title='Puzzles removed'
              description='Puzzles have been removed until further notice.'
              {...p}
            />
          )}
        />
        <Route path='/undefined/:title([a-zA-Z0-9-]+):contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={p => <Article {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/undefined/:title([a-zA-Z0-9-]+)-:contentKey([0-9]{5,})' render={p => <Article {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={p => <Article {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/:contentKey([0-9]{5,})' render={p => <Article {...p} contentKey={p.match.params.contentKey} />} />
        <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={p => <Redirect to={'/' + p.match.params.section} />} />
        <Route path='/:section([a-zA-Z0-9-]+)' render={p => <DefaultSection {...p} twitterName={process.env.RAZZLE_SITE_TWITTER_USER} />} />
      </Switch>
      <Footer toggleScrolling={toggleScrolling} section={props.section} visitorId={props.visitorId} sessionId={props.sessionId} />
      <PushPrompt section={props.section} visitorId={props.visitorId} sessionId={props.sessionId} />
      {process.env.RAZZLE_ENABLE_QUANTCAST
        ? null
        : <CookieForm />}
      {process.env.RAZZLE_ENABLE_NEWSLETTER_POPUPS &&
        <MobileNewsletterSubscribePopup />}
    </ThemeProvider>
  )
}

/**
 * enable Single Request Mode for Ads
 *
 * This setting requires a call to `performSingleRequest` to initiate the fetch. This is currently achieved using sagas,
 * leveraging `onSlotRegistered` to trigger an action, and debouncing the events to catch the last one in the series to
 * trigger the `performSingleRequest` call.
 */
enableSingleRequest()
// enableAnchorAds()
enableInterstitial()
if (process.env.RAZZLE_ENABLE_GAM && process.env.RAZZLE_ENABLE_LAZYLOAD) {
  enableLazyLoad()
}
if (process.env.RAZZLE_ENABLE_MAPPINGS) {
  enableMappings()
} else {
  disableMappings()
}

if (process.env.RAZZLE_ENABLE_AGE_TARGET) {
  enableAgeTarget()
} else {
  disableAgeTarget()
}

if (process.env.RAZZLE_ENABLE_GENDER_TARGET) {
  enableGenderTarget()
} else {
  disableGenderTarget()
}

if (process.env.RAZZLE_ENABLE_ARTICLE_LEVEL_TARGET) {
  enableArticleLevelTarget()
} else {
  disableArticleLevelTarget()
}

if (process.env.RAZZLE_ENABLE_SECTION_LEVEL_TARGET) {
  enableSectionLevelTarget()
} else {
  disableSectionLevelTarget()
}

const mapStateToProps = (state) => ({ ...state.app, ...{ visitorId: getVisitorId(state) }, ...{ sessionId: getSessionId(state) } })
const mapDispatchToProps = (dispatch) => bindActionCreators({ pageView }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

export { ErrorBoundary }
