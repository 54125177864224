import React, { useState, useEffect, useRef, useCallback } from 'react'
import { x } from '@xstyled/styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { bm } from '../../client/db'
import { getSessionId, getVisitorId, toggleFontSize } from '../../store/article'
import StyledLink from '../components/ui/Link'
import SearchBar from './MobileSearchBar'
import MobileArticleShare from '../components/MobileArticleShare'

import logo from '../../../assets/images/general/iol.svg'
import articleBookmark from '../../../assets/images/icons/bookmark-ico.svg'
import articleBookmarked from '../../../assets/images/icons/bookmarked-ico.svg'
import searchIco from '../../../assets/images/icons/search-ico.svg'

import { MobileHeaderElements } from './MobileHeader'

const handleBookmark = (article, bookmarked) => {
  const collectionMax = 10
  if (bookmarked) {
    return bm.messages.where('uuid').equals(article.contentKey).delete()
  } else {
    return bm.messages.add({ uuid: article.contentKey, article }).then(id =>
      bm.messages.where('id').below(id - (collectionMax - 1)).delete()
    )
  }
}

export function MobileArticleHeader (props) {
  const [bookmarked, setBookmarked] = useState(false)
  const [searchOpened, setSearchOpened] = useState(false)
  const searchBox = useRef(null)
  const clickSource = 'Mobile Article Header'
  useEffect(() => {
    if (props.article) {
      bm.messages.filter(entry => {
        return entry.uuid === props.article.contentKey
      }).count().then(val => {
        if (val < 1) {
          return setBookmarked(false)
        }
        return setBookmarked(true)
      })
    }
  }, [props.article ? props.article.contentKey : false])

  useEffect(() => {
    if (searchBox.current) {
      searchBox.current.focus()
    }
  }, [searchBox.current])

  return (
    <x.div w='100%' visibility={{ sm: props.visible ? 'visible' : 'hidden', lg: 'hidden' }} display={{ sm: props.visible ? 'flex' : 'none', lg: 'none' }} flexDirection='column' position='relative' backgroundColor='white' boxShadow='0px 0px 4px rgba(0, 0, 0, 0.25)'>
      <x.div w='100vw' display='grid' alignItems='center' position='relative' gridTemplateColumns='auto 120px 48px' pl={20} pr={12} mt={10}>
        <x.div>
          <StyledLink to='/'><x.img src={logo} alt='Independent Online' minWidth={{ lg: '100%' }} h={{ sm: '30px' }} visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource} section={props.section} /></StyledLink>
        </x.div>
        <x.div display='flex' justifyContent='flex-end' alignItems='center' spaceX={10}>
          <x.a href='#' onClick={() => { handleBookmark(props.article, bookmarked); return setBookmarked(!bookmarked) }}>
            <x.img src={bookmarked ? articleBookmarked : articleBookmark} alt='Bookmark article to read later' />
          </x.a>
          <MobileArticleShare link={props.article ? props.article.canonicalUri : ''} />
          <x.a onClick={() => { setSearchOpened(!searchOpened); return false }} cursor='pointer'><x.img src={searchIco} alt='Search IOL' /></x.a>
          {!props.isNavOpen && searchOpened && <SearchBar searchOpened={searchOpened} closeHandler={setSearchOpened} searchRef={searchBox} />}
        </x.div>
        <x.div pl={10} display='grid' justifyContent='center' alignItems='center'>
          {props.children}
        </x.div>
      </x.div>
      <MobileHeaderElements section={props.article ? (props.article.section ? props.article.section : '') : ''} />
    </x.div>
  )
}

const mapStateToProps = (state) => ({ visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleFontSize }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MobileArticleHeader)
