'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DoNothingCache = undefined;

var _cache = require('./src/cache');

var _cache2 = _interopRequireDefault(_cache);

var _memoryCache = require('./src/memory-cache');

var _memoryCache2 = _interopRequireDefault(_memoryCache);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _memoryCache2.default;
exports.DoNothingCache = _cache2.default;