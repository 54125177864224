import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'

export const FETCH_MOST_READ_ARTICLES = 'api/FETCH_MOST_READ_ARTICLES'
export const FETCH_MOST_READ_ARTICLES_SUCCESS = 'api/FETCH_MOST_READ_ARTICLES_SUCCESS'
export const FETCH_MOST_READ_ARTICLES_ERROR = 'api/FETCH_MOST_READ_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/mostread' : process.env.RAZZLE_POPULAR_FEED
const cache = Cache()

async function fetchArticlesAPI (section) {
  const key = 'most-read/' + section
  const result = await cache.get(key)
  if (result) {
    console.log('fetchArticlesAPI:', 'Cache HIT', section)
    return result
  }
  return axios.get(SERVER_URL + '/' + section)
    .then(response => {
      return cache.set(key, response.data)
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchMostReadArticlesSaga ({ section }) {
  try {
    const mostReadArticles = yield call(fetchArticlesAPI, section)
    let payload = {}
    payload = {
      lastFetch: new Date(),
      hasMore: false,
      articles: mostReadArticles
    }
    yield put({ type: FETCH_MOST_READ_ARTICLES_SUCCESS, payload, section })
  } catch (e) {
    yield put({ type: FETCH_MOST_READ_ARTICLES_ERROR, payload: e.message })
  }
}

export function * watchFetchMostReadArticles () {
  yield takeLatest(FETCH_MOST_READ_ARTICLES, fetchMostReadArticlesSaga)
}

// Saga actions
export const fetchMostReadArticles = (section, limit) => ({ type: FETCH_MOST_READ_ARTICLES, section, limit })

const initialState = {}

export const Reducer = (state = initialState, { type, payload, section, meta }) => {
  const newPayload = {}

  switch (type) {
    case FETCH_MOST_READ_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_MOST_READ_ARTICLES_ERROR:
      newPayload[section] = {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      }
      return Object.assign({}, state, newPayload)
    case FETCH_MOST_READ_ARTICLES_SUCCESS:
      newPayload[section] = {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch,
        authorName: payload.authorName,
        sectionLabel: payload.sectionLabel
      }
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
