'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ionUtil = require('ion-util');

var _ionUtil2 = _interopRequireDefault(_ionUtil);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var firstCall = true;

var postIONAnalytics = function postIONAnalytics(events) {
  // Suppress a duplicate event on the client. NB: Crawlers will be tracked.
  // server/index.js performs two renders, so it is also necessary to suppress events on firstRender
  // article events are raised from fetch success, so suppression not needed
  if (firstCall && typeof window !== 'undefined') {
    firstCall = false;
    return;
  }
  events.forEach(function (event) {
    var _eventData = {};
    if (event.section) {
      _eventData.section = event.section;
    }
    if (event.author) {
      _eventData.author = event.author;
    }
    switch (event.event) {
      case 'ArticlePageview':
        try {
          (0, _ionUtil2.default)('pageView', 'article', event.visitorId, event.titleKey, event.sessionId, event.path, _eventData);
        } catch (error) {
          console.log(error);
        }
        break;
      case 'SectionPageview':
        try {
          if (event.firstRender) {
            return;
          }
          (0, _ionUtil2.default)('pageView', 'section', event.visitorId, event.titleKey, event.sessionId, event.virtualPageURL, _eventData);
        } catch (error) {
          console.log(error);
        }
        break;
      case 'HomePageview':
        try {
          if (event.firstRender) {
            return;
          }
          (0, _ionUtil2.default)('pageView', 'home', event.visitorId, event.titleKey, event.sessionId, event.virtualPageURL, _eventData);
        } catch (error) {
          console.log(error);
        }
        break;
      case 'StaticPageview':
        try {
          if (event.firstRender) {
            return;
          }
          (0, _ionUtil2.default)('pageView', 'static', event.visitorId, event.titleKey, event.sessionId, event.virtualPageURL, _eventData);
        } catch (error) {
          console.log(error);
        }
        break;
      default:
        break;
    }
  });
};

exports.default = postIONAnalytics;