import axios from 'axios'
import { call, all, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'
import municipalitiesMapping from '../app/components/election/municipalitiesMapping.js'

// Actions
export const UPDATE_EVENT = 'election/UPDATE_EVENT'
export const NAVIGATE = 'election/NAVIGATE'
export const COLLECT_DATA = 'election/COLLECT_DATA'
export const COLLECT_DATA_SUCCESS = 'election/COLLECT_DATA_SUCCESS'
export const COLLECT_DATA_ERROR = 'election/COLLECT_DATA_ERROR'

export const VOTER_DETAILS_SUCCESS = 'election/VOTER_DETAILS_SUCCESS'
export const VOTER_DETAILS_ERROR = 'election/VOTER_DETAILS_ERROR'
export const UPDATE_FROM_DATA = 'api/UPDATE_FROM_DATA'

export const COLLECT_PROVINCE_DATA = 'election/COLLECT_PROVINCE_DATA'
export const COLLECT_PROVINCE_DATA_SUCCESS = 'election/COLLECT_PROVINCE_DATA_SUCCESS'
export const COLLECT_PROVINCE_DATA_ERROR = 'election/COLLECT_PROVINCE_DATA_ERROR'

export const COLLECT_MUNICIPALITY_DATA = 'election/COLLECT_MUNICIPALITY_DATA'
export const COLLECT_MUNICIPALITY_DATA_SUCCESS = 'election/COLLECT_MUNICIPALITY_DATA_SUCCESS'
export const COLLECT_MUNICIPALITY_DATA_ERROR = 'election/COLLECT_MUNICIPALITY_DATA_ERROR'

export const SHOW_PROVINCE = 'election/SHOW_PROVINCE'
export const SHOW_MUNICIPALITY = 'election/SHOW_MUNICIPALITY'
export const VOTER_DETAILS = 'election/VOTER_DETAILS'

export const NPE_SEAT_CALCULATION_RESULTS = 'election/NPE_SEAT_CALCULATION_RESULTS'
export const NPE_SEAT_CALCULATION_RESULTS_SUCCESS = 'election/NPE_SEAT_CALCULATION_RESULTS_SUCCESS'
export const NPE_SEAT_CALCULATION_RESULTS_ERROR = 'election/NPE_SEAT_CALCULATION_RESULTS_ERROR'

export const NPE_BALLOT_RESULTS = 'election/NPE_BALLOT_RESULTS'
export const NPE_BALLOT_RESULTS_SUCCESS = 'election/NPE_BALLOT_RESULTS_SUCCESS'
export const NPE_BALLOT_RESULTS_ERROR = 'election/NPE_BALLOT_RESULTS_ERROR'

const provinceIdToName = {
  1: 'Eastern Cape',
  2: 'Free State',
  3: 'Gauteng',
  4: 'KwaZulu-Natal',
  5: 'Mpumalanga',
  6: 'Northern Cape',
  7: 'Limpopo',
  8: 'North West',
  9: 'Western Cape'
}
const provincesAbrevToName = {
  EC: 'Eastern Cape',
  FS: 'Free State',
  GT: 'Gauteng',
  KZN: 'KwaZulu-Natal',
  MP: 'Mpumalanga',
  NC: 'Northern Cape',
  LIM: 'Limpopo',
  NW: 'North West',
  WC: 'Western Cape'
}

const provincesNameToAbrev = {
  'Eastern Cape': 'EC',
  'Free State': 'FS',
  Gauteng: 'GT',
  'KwaZulu-Natal': 'KZN',
  Mpumalanga: 'MP',
  'Northern Cape': 'NC',
  Limpopo: 'LIM',
  'North West': 'NW',
  'Western Cape': 'WC'
}

const centerCoordinates = {
  National: { center: [25, -28], zoom: 13 },
  'Eastern Cape': { center: [26.5, -32], zoom: 38 },
  'Free State': { center: [27, -28.75], zoom: 42 },
  Gauteng: { center: [28, -26], zoom: 65 },
  'KwaZulu-Natal': { center: [30, -29], zoom: 40 },
  Limpopo: { center: [29, -24], zoom: 40 },
  Mpumalanga: { center: [30, -25.75], zoom: 45 },
  'North West': { center: [25, -26], zoom: 40 },
  'Northern Cape': { center: [23, -29], zoom: 22 },
  'Western Cape': { center: [21, -33], zoom: 44 }
}

const SERVER_URL = typeof window !== 'undefined' ? '/data/elections' : process.env.RAZZLE_ELECTIONS
const cache = Cache()
async function fetchAndCache (key) {
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios.get(`${SERVER_URL}/${key}`)
    .then(response => {
      // console.log('fetchAndCache', `${SERVER_URL}/${key}`), response.data
      return cache.set(key, response.data)
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}
async function getElectionsTypeResults (resultsType, eventId) {
  return fetchAndCache(`${resultsType}/${eventId}`)
}
async function fetchRegionResultsApi (eventId, provinceId) {
  return fetchAndCache(`municipalityResults/${eventId}/${provinceId}`)
}
async function fetchMunicipalityResultsApi (eventId, provinceId, municipalityId) {
  return fetchAndCache(`wardResults/${eventId}/${provinceId}/${municipalityId}`)
}
async function fetchVoterDetailsApi (id) {
  return fetchAndCache(`voterDetails/${id}`)
}
async function fetchNPESeatCalculationResultsApi (eventId) {
  return fetchAndCache(`npeSeatCalculationResults/${eventId}`)
}
async function fetchNPEBallotResultsApi (eventId) {
  return fetchAndCache(`npeBallotResults/${eventId}`)
}

// Helpers
export const swapProvinceAbrevName = (province) => {
  if (province.length > 3) {
    return provincesNameToAbrev[province]
  }
  return provincesAbrevToName[province]
}

export const mapMunicipalityId = (inputProvinceId, inputMunicName) => {
  const provinceData = municipalitiesMapping[parseInt(inputProvinceId) - 1]
  const municipality = provinceData.filter(aMunicipality => aMunicipality.Municipality.toLowerCase().includes(inputMunicName.toLowerCase()))
  return municipality[0].MunicipalityID
}

// Actions
export const collectResults = (eventId) => ({ type: COLLECT_DATA, eventId })
export const showProvince = (eventId, provinceId) => ({ type: SHOW_PROVINCE, eventId, provinceId })
export const showMunicpality = (eventId, provinceId, municipalityName) => ({ type: SHOW_MUNICIPALITY, eventId, provinceId, municipalityName })
export const navigate = (province, municipality, provinceId, municipalityId, mapType) => ({ type: 'NAVIGATE', province, municipality, provinceId, municipalityId, mapType })
export const getRegionResults = (data) => ({ type: COLLECT_PROVINCE_DATA, data })
export const updateEvent = (eventId, electionYear) => ({ type: UPDATE_EVENT, eventId, electionYear })
export const showVoterDetails = (id) => ({ type: VOTER_DETAILS, id })
export const updateForm = (data) => ({ type: UPDATE_FROM_DATA, payload: data })
export const getNPESeatCalculationResults = (eventId) => ({ type: NPE_SEAT_CALCULATION_RESULTS, eventId })
export const getBallotResults = (eventId) => ({ type: NPE_BALLOT_RESULTS, eventId })

const initialState = {
  mapType: 'national',
  fill: false,
  center: [25, -28],
  zoom: 13,
  provinceName: null,
  provinceId: null,
  municipalityId: null,
  municipalityName: null,
  provincialResults: [],
  nationalResults: [],
  hasError: false,
  eventId: 1334,
  electionYear: 2024,
  idNumber: null,
  voterDetails: null,
  ballotResults: null,
  seatCalculationResults: null,
  formOutput: ''
}

function * getElectionsResults ({ eventId }) {
  const results = {}
  const resultsTypes = ['nationalResults', 'provincialResults']
  try {
    yield Promise.all(resultsTypes.map(resultsType =>
      getElectionsTypeResults(resultsType, eventId).then(electionsTypeResult => {
        results[resultsType] = electionsTypeResult
        console.log('Results: ', results)
        return results
      })
    ))
    yield put({ type: COLLECT_DATA_SUCCESS, payload: results })
  } catch (error) {
    yield put({ type: COLLECT_DATA_ERROR, payload: error.message })
  }
}

function * fetchRegionResults ({ eventId, provinceId }) {
  let results
  try {
    results = yield call(fetchRegionResultsApi, eventId, provinceId)
    yield put({ type: COLLECT_PROVINCE_DATA_SUCCESS, payload: results, provinceId })
  } catch (error) {
    yield put({ type: COLLECT_PROVINCE_DATA_ERROR, payload: error.message })
  }
}

function * fetchMunicipalityResults ({ eventId, provinceId, municipalityName }) {
  let results
  try {
    const municipalityId = mapMunicipalityId(provinceId, municipalityName)
    results = yield call(fetchMunicipalityResultsApi, eventId, provinceId, municipalityId)
    yield put({ type: COLLECT_MUNICIPALITY_DATA_SUCCESS, payload: results, provinceId, municipalityId, municipalityName })
  } catch (error) {
    yield put({ type: COLLECT_MUNICIPALITY_DATA_ERROR, payload: error.message })
  }
}

function * fetchVoterDetails ({ id }) {
  let results
  try {
    results = yield call(fetchVoterDetailsApi, id)
    yield put({ type: VOTER_DETAILS_SUCCESS, payload: results })
  } catch (error) {
    yield put({ type: VOTER_DETAILS_ERROR, payload: error.message })
  }
}

function * fetchNPEBallotResults ({ eventId }) {
  let results
  try {
    results = yield call(fetchNPEBallotResultsApi, eventId)
    yield put({ type: NPE_BALLOT_RESULTS_SUCCESS, payload: results })
  } catch (error) {
    yield put({ type: NPE_BALLOT_RESULTS_ERROR, payload: error.message })
  }
}

function * fetchNPESeatCalculationResults ({ eventId }) {
  let results
  try {
    results = yield call(fetchNPESeatCalculationResultsApi, eventId)
    yield put({ type: NPE_SEAT_CALCULATION_RESULTS_SUCCESS, payload: results })
  } catch (error) {
    yield put({ type: NPE_SEAT_CALCULATION_RESULTS_ERROR, payload: error.message })
  }
}

export function * watchElections () {
  yield all([
    takeLatest(COLLECT_DATA, getElectionsResults),
    takeLatest(SHOW_PROVINCE, fetchRegionResults),
    takeLatest(SHOW_MUNICIPALITY, fetchMunicipalityResults),
    takeLatest(VOTER_DETAILS, fetchVoterDetails),
    takeLatest(NPE_BALLOT_RESULTS, fetchNPEBallotResults),
    takeLatest(NPE_SEAT_CALCULATION_RESULTS, fetchNPESeatCalculationResults)
  ])
}

export const Reducer = (state = initialState, action) => {
  let center = [25, -28]
  let zoom = 13
  const ProvinceName = provinceIdToName[action.provinceId]
  switch (action.type) {
    case SHOW_PROVINCE:
      return state
    case NAVIGATE:{
      let provinceName = null
      let provinceId = null
      let municipalityName = null
      let municipalityId = null
      if (action.mapType === 'municipal') {
        center = centerCoordinates[action.province].center
        zoom = centerCoordinates[action.province].zoom
        provinceName = swapProvinceAbrevName(action.province)
        provinceId = action.provinceId
        municipalityName = action.municipality
        municipalityId = mapMunicipalityId(action.provinceId, action.municipality)
      }
      return Object.assign({}, state, {
        fill: false, mapType: action.mapType, center, zoom, provinceName, provinceId, municipalityName, municipalityId
      })
    }
    case COLLECT_DATA_SUCCESS:
      return Object.assign({}, state, {
        fill: true,
        mapType: 'national',
        center: centerCoordinates.National.center,
        zoom: centerCoordinates.National.zoom,
        provincialResults: action.payload.provincialResults,
        nationalResults: action.payload.nationalResults
      })
    case COLLECT_DATA_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        errorMessage: action.payload
      })
    case COLLECT_PROVINCE_DATA_SUCCESS:
      return Object.assign({}, state, {
        fill: true,
        mapType: 'provincial',
        center: centerCoordinates[ProvinceName].center,
        zoom: centerCoordinates[ProvinceName].zoom,
        provinceName: swapProvinceAbrevName(ProvinceName),
        provinceId: action.provinceId,
        municipalResults: action.payload
      })
    case COLLECT_MUNICIPALITY_DATA_SUCCESS:
      return Object.assign({}, state, {
        fill: true,
        mapType: 'municipal',
        center: centerCoordinates[ProvinceName].center,
        zoom: centerCoordinates[ProvinceName].zoom,
        provinceName: swapProvinceAbrevName(ProvinceName),
        provinceId: action.provinceId,
        municipalityName: action.municipalityName,
        municipalityId: action.municipalityId,
        wardResults: action.payload
      })
    case VOTER_DETAILS:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmitting: true,
        hasSubmitted: false,
        hasError: false
      })
    case UPDATE_FROM_DATA:
      return Object.assign({}, state, action.payload)
    case VOTER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        voterDetails: action.payload,
        isSubmitting: false,
        hasSubmitted: true,
        didInvalidate: false
      })
    case VOTER_DETAILS_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload,
        isSubmitting: false,
        hasSubmitted: false,
        didInvalidate: true
      })
    case UPDATE_EVENT:
      console.log('UPDATE_EVENT', action.eventId, action.electionYear)
      return Object.assign({}, state, {
        fill: false,
        eventId: action.eventId,
        electionYear: action.electionYear
      })
    case NPE_BALLOT_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        ballotResults: action.payload
      })
    case NPE_BALLOT_RESULTS_ERROR: {
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload
      })
    }
    case NPE_SEAT_CALCULATION_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        seatCalculationResults: action.payload
      })
    case NPE_SEAT_CALCULATION_RESULTS_ERROR: {
      return Object.assign({}, state, {
        hasError: true,
        error: action.payload
      })
    }
    default:
      return state
  }
}
