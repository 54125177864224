import { NavLink } from 'react-router-dom'
import ionPostAnalytics from '../../../lib/ionPostAnalytics'
import { getPageType } from '../../../store/app'

const MyLink = ({ className, id, to, key, replace, onClick, isActive, children, visitorId, sessionId, section, source }) => {
  if (typeof window !== 'undefined' && source !== undefined) {
    const locationUrl = window.location.href
    const destinationUrl = to
    const referrerUrl = window.document.referrer
    const scrollDepth = window.scrollY
    const _eventData = { source, destinationUrl, referrerUrl, scrollDepth }
    if (section) {
      _eventData.section = section
    }
    const onClickAnayltics = () => {
      ionPostAnalytics('navigation', getPageType(), visitorId, process.env.RAZZLE_TITLE_KEY, sessionId, locationUrl, _eventData)
      if (onClick) {
        onClick()
      }
    }
    return (
      <NavLink key={key} isActive={isActive} id={id} to={to} replace={replace} onClick={onClickAnayltics} className={className}>{children}</NavLink>
    )
  }
  return (
    <NavLink key={key} isActive={isActive} id={id} to={to} replace={replace} onClick={onClick} className={className}>{children}</NavLink>
  )
}

export default MyLink
