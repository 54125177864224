import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'

export const FETCH_TRENDING_ARTICLES = 'api/FETCH_TRENDING_ARTICLES'
export const FETCH_TRENDING_ARTICLES_SUCCESS = 'api/FETCH_TRENDING_ARTICLES_SUCCESS'
export const FETCH_TRENDING_ARTICLES_ERROR = 'api/FETCH_TRENDING_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/trending-articles/iol' : process.env.RAZZLE_CONTENT + '/trending-articles/iol'
const section = 'trendingarticles'
const cache = Cache()

async function fetchArticlesAPI () {
  const result = await cache.get(section)
  if (result) {
    return result
  }
  return axios.get(SERVER_URL)
    .then(response => {
      return cache.set(section, response.data)
    })
    .catch(err => {
      throw err
    })
}

function * fetchTrendingArticlesSaga () {
  try {
    const res = yield call(fetchArticlesAPI)
    let payload = {}
    if (res) {
      payload = {
        lastFetch: new Date(),
        hasMore: false,
        articles: res.contents,
        sectionsLabels: res.sectionsLabels
      }
    }
    yield put({ type: FETCH_TRENDING_ARTICLES_SUCCESS, payload })
  } catch (e) {
    yield put({ type: FETCH_TRENDING_ARTICLES_ERROR, payload: e.message })
  }
}

export function * watchTrendingArticles () {
  yield takeLatest(FETCH_TRENDING_ARTICLES, fetchTrendingArticlesSaga)
}

// Saga actions
export const fetchTrendingArticles = () => ({ type: FETCH_TRENDING_ARTICLES })

const initialState = {
  trendingarticles: {
    didInvalidate: false,
    isFetching: false,
    hasFetched: false,
    hasError: false,
    error: null,
    articles: [], // do not mutate these
    sectionsLabels: []
  }
}

export const Reducer = (state = initialState, { type, payload }) => {
  const newPayload = {}
  switch (type) {
    case FETCH_TRENDING_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_TRENDING_ARTICLES_ERROR:
      newPayload[section] = Object.assign({}, state[section], {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
      return Object.assign({}, state, newPayload)
    case FETCH_TRENDING_ARTICLES_SUCCESS:
      newPayload[section] = Object.assign({}, state[section], {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch,
        sectionsLabels: payload.sectionsLabels
      })
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
