import React, { useState, useEffect } from 'react'
import { x } from '@xstyled/styled-components'
import articleShare from '../../../assets/images/social-media/share.svg'
import { checkPropTypes } from 'prop-types'

const MobileArticleShare = ({link, w, h}) => {
  const [navigatorSharePresent, setNavigatorSharePresent] = useState(false)
  useEffect(() => {
    if (navigator.canShare) {
      setNavigatorSharePresent(true)
    }
  })
  const handleShareButton = (url) => {
    navigator
      .share({
        url: url
      })
      .then(() => {
        console.log("Sharing successfull")
      })
      .catch(() => {
        console.log("Sharing failed")
      })
  }
  return (
    <>
      {navigatorSharePresent &&
        <x.img src={articleShare} w={w} h={h} onClick={() => handleShareButton(process.env.RAZZLE_SITE_URL + link)} title='Share Article' alt='Share Article' />
      }
    </>
  )
}

export default MobileArticleShare
