const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

export function deepCompare (a, b) {
  return JSON.stringify(a, getCircularReplacer()) === JSON.stringify(b, getCircularReplacer())
}

export default deepCompare
