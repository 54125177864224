import React, { useState } from 'react'
import { x } from '@xstyled/styled-components'
import {StyledSelectedTabButton, StyledTabButton} from './Tabs.style'

function TabButton({selected, id,setSelected, onTabChange, children, ...rest }) {
  if (selected === id) {
    return (
      <StyledSelectedTabButton role='tab' ariaSelected='true' id={id} {...rest}>{children}</StyledSelectedTabButton>
    )
  }
  return (
    <StyledTabButton role='tab' ariaSelected='false' id={id} onClick={() => { 
      setSelected(id)
      if (onTabChange) {
        onTabChange()
      }
    }} {...rest}>{children}</StyledTabButton>
  )
}

function TabPanel({selected, id, children, ...rest}) {
  return (
    <x.div role='tabpanel' visible={selected === id ? 'true' : 'false'} id={id + '-tab'} {...rest} display={selected === id ? 'block' : 'none'}>{children}</x.div>
  )
}

function TabList(props) {
  return (
    <x.div role='tablist' {...props}>
      {props.children}
    </x.div>
  )
}

export const useTabState = ({selectedId}) => {
  const [selected, setSelected] = useState(selectedId)
  return {
    selected, setSelected
  }
}

export const Tab = {}
Tab.List = TabList
Tab.Tab = TabButton
Tab.Panel = TabPanel
