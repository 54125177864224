/**
 * Hook that returns false while the page is being hydrated, and true when rendering starts
 * @returns Boolean
 */
import React from 'react'

function useHasMounted () {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

export default useHasMounted
