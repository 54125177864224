import { all } from 'redux-saga/effects'
import { watchFetchArticles } from './articles'
import { watchFetchMostReadArticles } from './mostReadArticles'
import { watchTrendingArticles } from './trendingArticles'
import { watchFetchTrendsList } from './trends'
import { watchFetchArticleContent, watchFetchArticle, watchPreviewArticle, watchSubmitCompetition } from './article'
import { watchNavChanges, watchPrecache } from './app'
import { watchFetchStaticPage } from './staticPage'
import { watchFeedback } from './feedback'
import { watchSubscribe } from './subscribe'
import { watchfetchEditorsChoiceArticles } from './editorsChoice'
import { watchFetchOtherPublicationsArticles } from './otherPublications'
import { watchFetchWeather } from './weather'
import { watchFetchAuthor } from './author'
import { watchFetchAuthors } from './authors'
import { watchFetchDigiserts } from './digiserts'
import { watchElections } from './elections'

export default function * rootSaga () {
  try {
    yield all([
      watchFetchArticleContent(),
      watchFetchArticle(),
      watchPreviewArticle(),
      watchFetchArticles(),
      watchFetchMostReadArticles(),
      watchTrendingArticles(),
      watchFetchTrendsList(),
      // watchFetchRssArticles(),
      watchNavChanges(),
      watchFeedback(),
      watchPrecache(),
      watchFetchStaticPage(),
      watchSubscribe(),
      watchSubmitCompetition(),
      watchfetchEditorsChoiceArticles(),
      watchFetchOtherPublicationsArticles(),
      watchFetchWeather(),
      watchFetchAuthor(),
      watchFetchAuthors(),
      watchFetchDigiserts(),
      watchElections()
    ])
  } catch (e) {
    console.error('ERROR Saga ERROR', e)
    throw e
  }
}
