import styled, { css, system } from '@xstyled/styled-components'
import { Link } from 'react-router-dom'
import ionPostAnalytics from '../../../lib/ionPostAnalytics'
import { getPageType } from '../../../store/app'

const MyLink = ({ className, id, to, key, replace, onClick, children, visitorId, sessionId, section, source, dataAuthor }) => {
  if (typeof window !== 'undefined' && source !== undefined) {
    const locationUrl = window.location.href
    const destinationUrl = to
    const referrerUrl = window.document.referrer
    const scrollDepth = window.scrollY
    const _eventData = { source, destinationUrl, referrerUrl, scrollDepth }
    if (section) {
      _eventData.section = section
    }
    const onClickAnayltics = () => {
      ionPostAnalytics('navigation', getPageType(), visitorId, process.env.RAZZLE_TITLE_KEY, sessionId, locationUrl, _eventData)
      if (onClick) {
        onClick()
      }
    }
    return (
      <Link key={key} id={id} to={to} replace={replace} onClick={onClickAnayltics} className={className} data-author={dataAuthor}>{children}</Link>
    )
  }
  return (
    <Link key={key} id={id} to={to} replace={replace} onClick={onClick} className={className} data-author={dataAuthor}>{children}</Link>
  )
}

const StyledLink = styled(MyLink)(
  ({ color, hoverColor }) => css`
  ${system}
    color: ${color || '#474747'};
    &.active {
      color: #B41400;
      &::after {
        content: '';
        position: absolute;
        background: #B41400;
        bottom: -10px;
        left: 0;
        height: 3px;
        width: 100%;
      }
    }
    &:hover {
    * {
      color: ${hoverColor || '#000'};
    }
  }
  `
)

export default StyledLink
