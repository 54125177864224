"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Reducer = exports.updateNavPath = exports.navigateTo = exports.NAVIGATE_TO = exports.SET_NAVPATH = void 0;
const SET_NAVPATH = 'SET_NAVPATH';
exports.SET_NAVPATH = SET_NAVPATH;
const NAVIGATE_TO = 'NAVIGATE_TO';
exports.NAVIGATE_TO = NAVIGATE_TO;

const navigateTo = navPath => ({
  type: NAVIGATE_TO,
  payload: navPath
});

exports.navigateTo = navigateTo;

const updateNavPath = navPath => ({
  type: SET_NAVPATH,
  payload: navPath
});

exports.updateNavPath = updateNavPath;

const Reducer = (state = {
  navPath: '/'
}, {
  type,
  payload
}) => {
  switch (type) {
    case NAVIGATE_TO:
    case SET_NAVPATH:
      return { ...state,
        ...{
          navPath: payload
        }
      };

    default:
      return state;
  }
};

exports.Reducer = Reducer;