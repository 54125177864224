import React, { useEffect, useRef, useCallback } from 'react'
import { x } from '@xstyled/styled-components'

import closeBtn from '../../../assets/images/icons/close-btn.svg'
import searchGry from '../../../assets/images/icons/search-input-wht-ico.svg'

export const SearchBar = ({ closeHandler, searchRef, backGroundColor }) => {
  const formRef = useRef(undefined)
  const handleFormFocusOut = useCallback((event) => {
    if (closeHandler && formRef.current) {
      if (!formRef.current.contains(event.relatedTarget)) {
        closeHandler(false)
      }
    }
  })
  useEffect(()=>{
    formRef.current.addEventListener('focusout', handleFormFocusOut)
    return (() => formRef.current.removeEventListener('focusout', handleFormFocusOut))
  }, [formRef.current])
  
  return (
    <x.form ref={formRef} action='/search' method='get' display='grid' gridTemplateColumns='auto 38px' pr={16} gridGap={0} position='relative' onSubmit={(e) => {
      const formData = new FormData(e.target)
      if (!formData.get('q')) {
        e.preventDefault()
      }
    }}>
      <x.input type='search' placeholder='Search IOL' name='q' backgroundColor={backGroundColor || '#FFF'} p='10px 40px 10px 10px' borderRadius={3} w='100%' ref={searchRef} />
      <x.button type='submit' m={0} p={0} w={38} bg='grey'><x.img width={24} height={24} mx='auto' src={searchGry} /></x.button>
      {closeHandler &&
        <x.img src={closeBtn} onClick={() => closeHandler(false)} cursor='pointer' position='absolute' m={0} top='-1.125rem' right='-1.0rem' width={24} height={24} />
      }
    </x.form>
  )
}

export const SearchBarPopup = ({ searchOpened, closeHandler, searchRef, maxWidth }) => {
  return (
    <x.div w='100%' p={20} position='absolute' zIndex='99999' top='71px' left='calc(-0.625rem * calc(1 - var(--x-space-x-reverse)))' alignItems='center' backgroundColor='vltGrey' maxWidth={maxWidth}>
      <SearchBar closeHandler={closeHandler} searchRef={searchRef} />
    </x.div>
  )
}

export default SearchBarPopup
