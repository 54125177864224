'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IONAnalytics = function IONAnalytics(events) {
  events.forEach(function (event) {
    switch (event.event) {
      case 'ArticlePageview':
        try {
          var host = event.host.split('//')[1].split(':')[0];
          createUrlAndSend([host, 'a', event.contentKey, event.section], event.visitorId);
        } catch (error) {
          console.log(error);
        }
        break;
      default:
        break;
    }
  });
};

function createUrlAndSend(parts, visitorId) {
  var customVisitorId = visitorId || Math.random().toString(36).substr(2, 9);
  var duration = Date.now();
  var timeNow = new Date().getTime();
  var url = 'https://ai.iol.io/aa/' + parts.join('/');

  if (typeof window !== 'undefined') {
    url += '?w=' + window.outerWidth + '&h=' + window.outerHeight + '&u=' + customVisitorId + '&t=' + duration + '&ts=' + timeNow;
  } else {
    url += '?w=0&h=0&u=' + customVisitorId + '&t=' + duration + '&ts=' + timeNow;
  }
  _axios2.default.get(url).catch(function (error) {
    console.warn('IONAnalytics: Error ' + (error && error.status) + ' accessing', url);
  });
}
exports.default = IONAnalytics;