import { combineReducers } from 'redux'
import { Reducer as articles } from './articles'
import { Reducer as mostReadArticles } from './mostReadArticles'
import { Reducer as trendingArticles } from './trendingArticles'
import { Reducer as trends } from './trends'
import { Reducer as article } from './article'
import { Reducer as app } from './app'
import { Reducer as staticPage } from './staticPage'
import { Reducer as feedback } from './feedback'
import { Reducer as subscribe } from './subscribe'
import { Reducer as editorsChoice } from './editorsChoice'
import { Reducer as otherPublications } from './otherPublications'
import { Reducer as weather } from './weather'
import { Reducer as user } from './user'
import { Reducer as author } from './author'
import { Reducer as authors } from './authors'
import { Reducer as digiserts } from './digiserts'
import { Reducer as elections } from './elections'

export default combineReducers({
  article,
  articles,
  mostReadArticles,
  trendingArticles,
  trends,
  app,
  staticPage,
  feedback,
  subscribe,
  editorsChoice,
  otherPublications,
  weather,
  user,
  author,
  authors,
  digiserts,
  elections
})
