import React, { useEffect, useState } from "react"
import styled, { x, system } from '@xstyled/styled-components'
import arrowUp from '../../../../assets/images/icons/arrow-up.svg'

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <x.div position='fixed' zIndex='1000' bottom={{ sm: '130px', lg: '110px' }} right={10} cursor='pointer' borderRadius='full' backgroundColor='ltGrey' w={42} he={42} display='block' className='scroll-to-top'>
      {isVisible && (
        <x.div onClick={scrollToTop}>
          <x.img src={arrowUp} alt="Go to top" w='100%' h='100%' display='block' />
        </x.div>
      )}
    </x.div>
  );
}