import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'

export const FETCH_EDITORS_CHOICE_ARTICLES = 'api/FETCH_EDITORS_CHOICE_ARTICLES'
export const FETCH_EDITORS_CHOICE_ARTICLES_SUCCESS = 'api/FETCH_EDITORS_CHOICE_ARTICLES_SUCCESS'
export const FETCH_EDITORS_CHOICE_ARTICLES_ERROR = 'api/FETCH_EDITORS_CHOICE_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/editorschoice/' : process.env.RAZZLE_EDITOR_CHOICE
const section = 'editorschoice'
const cache = Cache()

async function fetchArticlesAPI () {
  const result = await cache.get(section)
  if (result) {
    return result
  }
  return axios.get(SERVER_URL)
    .then(response => {
      return cache.set(section, response.data)
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchEditorsChoiceArticlesSaga () {
  try {
    const res = yield call(fetchArticlesAPI)
    let payload = {}
    payload = res.contents
    yield put({ type: FETCH_EDITORS_CHOICE_ARTICLES_SUCCESS, payload })
  } catch (e) {
    yield put({ type: FETCH_EDITORS_CHOICE_ARTICLES_ERROR, payload: e.message })
  }
}

export function * watchfetchEditorsChoiceArticles () {
  yield takeLatest(FETCH_EDITORS_CHOICE_ARTICLES, fetchEditorsChoiceArticlesSaga)
}

// Saga actions
export const fetchEditorsChoiceArticles = (section) => ({ type: FETCH_EDITORS_CHOICE_ARTICLES, section })

const initialState = {
  editorschoice: {
    didInvalidate: false,
    isFetching: false,
    hasFetched: false,
    hasError: false,
    error: null,
    articles: [] // do not mutate these
  }
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  const newPayload = {}
  switch (type) {
    case FETCH_EDITORS_CHOICE_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_EDITORS_CHOICE_ARTICLES_ERROR:
      newPayload[section] = Object.assign({}, state[section], {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
      return Object.assign({}, state, newPayload)
    case FETCH_EDITORS_CHOICE_ARTICLES_SUCCESS:
      newPayload[section] = Object.assign({}, state[section], {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload
      })
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
