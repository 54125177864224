import axios from 'axios'
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { fetchArticleAPI } from './article'
import Cache from '../lib/cache'

export const FETCH_ARTICLES = 'api/FETCH_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'api/FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_ERROR = 'api/FETCH_ARTICLES_ERROR'
export const FETCH_ARTICLES_NOTFOUND = 'api/FETCH_ARTICLES_NOTFOUND'

export const FETCH_FULL_ARTICLES = 'api/FETCH_FULL_ARTICLES'
export const FETCH_FULL_ARTICLES_SUCCESS = 'api/FETCH_FULL_ARTICLES_SUCCESS'
export const FETCH_FULL_ARTICLES_ERROR = 'api/FETCH_FULL_ARTICLES_ERROR'
export const FETCH_FULL_ARTICLES_NOTFOUND = 'api/FETCH_FULL_ARTICLES_NOTFOUND'

export const FETCH_MORE_ARTICLES = 'api/FETCH_MORE_ARTICLES'
export const FETCH_MORE_ARTICLES_SUCCESS = 'api/FETCH_MORE_ARTICLES_SUCCESS'
export const FETCH_MORE_ARTICLES_ERROR = 'api/FETCH_MORE_ARTICLES_ERROR'

export const SET_SECTION_TITLE = 'api/SET_SECTION_TITLE'

const SERVER_URL = typeof window !== 'undefined' ? '/data/content' : process.env.RAZZLE_CONTENT
const cache = Cache()

export async function fetchArticlesAPI (section, offset, limit, filter) {
  if (offset === 0) {
    const result = await cache.get(section)
    if (result && result.contents && result.contents.length >= limit) {
      // console.log('fetchArticlesAPI', 'HIT', section, limit)
      result.contents = result.contents.slice(0, limit)
      return result
    }
    limit = Math.max(10, limit)
  }
  const urlFilter = filter ? '?filter=' + filter : ''
  // console.log('Fetching', SERVER_URL + '/v3/section/' + process.env.RAZZLE_TITLE_KEY + '/' + offset + '/' + limit + '/' + section + urlFilter)
  return axios.get(SERVER_URL + '/v3/section/' + process.env.RAZZLE_TITLE_KEY + '/' + offset + '/' + limit + '/' + section + urlFilter)
    .then(response => {
      if (offset === 0) {
        return cache.set(section, response.data, 3 * 60000)
      } else {
        return Promise.resolve(response.data)
      }
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

export async function fetchFullArticlesAPI (section, offset, limit, filter) {
  return fetchArticlesAPI(section, offset, limit, filter)
    .then(articles => Promise.all(articles.contents.map(article => fetchArticleAPI(article.contentKey))))
}

function * fetchArticlesSaga ({ section, offset, limit, filter }) {
  try {
    // const start = new Date()
    const res = yield call(fetchArticlesAPI, section, offset, limit + 1, filter)
    // console.log('Fetched', section, offset, limit, 'articles in', new Date() - start, 'ms')
    let payload = { }
    if (res.contents) {
      payload = {
        lastFetch: new Date(),
        sectionsLabels: res.sectionsLabels,
        hasMore: res.contents.length > limit,
        articles: res.contents.slice(0, limit),
        authorName: res['author-name'] ? res['author-name'] : null
      }
    }
    yield put({ type: FETCH_ARTICLES_SUCCESS, payload, section })
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put({ type: FETCH_ARTICLES_NOTFOUND, section, message: e.message })
    } else {
      yield put({ type: FETCH_ARTICLES_ERROR, section, message: e.message })
    }
  }
}

function * fetchFullArticlesSaga ({ section, offset, limit, filter }) {
  try {
    // const start = new Date()
    const res = yield call(fetchFullArticlesAPI, section, offset, limit + 1, filter)
    // console.log('Fetched', section, offset, limit, 'articles in', new Date() - start, 'ms')
    let payload = { }
    if (res) {
      payload = {
        lastFetch: new Date(),
        hasMore: res.length > limit,
        articles: res.slice(0, limit),
        authorName: null
      }
    }
    yield put({ type: FETCH_FULL_ARTICLES_SUCCESS, payload, section })
  } catch (e) {
    console.error('ERROR while fetching section', section)
    if (e.response && e.response.status === 404) {
      yield put({ type: FETCH_FULL_ARTICLES_NOTFOUND, section, message: e.message })
    } else {
      yield put({ type: FETCH_FULL_ARTICLES_ERROR, section, message: e.message })
    }
  }
}

function * fetchMoreArticlesSaga ({ section, offset, limit }) {
  try {
    // console.log('fetchMoreArticlesSaga')
    let data = yield select(getArticles, section)
    if (data.articles.length < offset + limit) {
      const res = yield call(fetchArticlesAPI, section, data.articles.length, limit + 1)
      data = {
        section,
        hasMore: res.contents.length > limit,
        sectionsLabels: res.sectionsLabels,
        articles: data.articles.concat(res.contents.slice(0, limit))
      }
    } else {
      data = {
        section,
        hasMore: true,
        sectionsLabels: [],
        articles: data.articles
      }
    }
    yield put({ type: FETCH_MORE_ARTICLES_SUCCESS, payload: data })
  } catch (e) {
    console.error('ERROR while fetching section', section, 'json:', e.message)
    yield put({ type: FETCH_MORE_ARTICLES_ERROR, payload: { section, message: e.message } })
  }
}

export function * watchFetchArticles () {
  yield takeEvery(FETCH_ARTICLES, fetchArticlesSaga)
  yield takeLatest(FETCH_MORE_ARTICLES, fetchMoreArticlesSaga)
  yield takeEvery(FETCH_FULL_ARTICLES, fetchFullArticlesSaga)
}

// Saga actions
export const getArticles = (state, section) => state.articles[section]
export const getAllArticles = (state, section) => state.articles
export const fetchFullArticles = (section, offset, limit, filter) => ({ type: FETCH_FULL_ARTICLES, section, offset, limit, filter })
export const fetchArticles = (section, offset, limit, filter) => ({ type: FETCH_ARTICLES, section, offset, limit, filter })
export const fetchMoreArticles = (section, offset, limit) => ({ type: FETCH_MORE_ARTICLES, section, offset, limit })
export const setSectionTitle = (payload) => ({ type: SET_SECTION_TITLE, payload })

export const Reducer = (state = {}, { type, payload, meta, section, message }) => {
  const newPayload = {}

  switch (type) {
    case FETCH_MORE_ARTICLES:
    case FETCH_ARTICLES:
    case FETCH_FULL_ARTICLES:
      newPayload[section] = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        isFetchingMore: type === FETCH_MORE_ARTICLES,
        hasFetchedMore: false,
        hasFetched: false,
        hasError: false,
        is404: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_ARTICLES_NOTFOUND:
    case FETCH_FULL_ARTICLES_NOTFOUND:
      newPayload[section] = {
        hasError: true,
        is404: true,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        checkCanonical: false,
        canonical: null
      }
      return Object.assign({}, state, newPayload)
    case FETCH_ARTICLES_ERROR:
    case FETCH_FULL_ARTICLES_ERROR:
    case FETCH_MORE_ARTICLES_ERROR:
      console.log('Proccessing ERROR', type, payload, meta, section, message)
      newPayload[section] = {
        hasError: true,
        is404: false,
        error: message,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      }
      return Object.assign({}, state, newPayload)
    case FETCH_MORE_ARTICLES_SUCCESS:
      newPayload[payload.section] = {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        isFetchingMore: false,
        hasFetchedMore: true,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch,
        authorName: payload.authorName,
        sectionsLabels: payload.sectionsLabels
      }
      return Object.assign({}, state, newPayload)
    case FETCH_FULL_ARTICLES_SUCCESS:
    case FETCH_ARTICLES_SUCCESS:
      newPayload[section] = {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload.articles,
        hasMore: payload.hasMore,
        lastFetch: payload.lastFetch,
        authorName: payload.authorName,
        sectionsLabels: payload.sectionsLabels
      }
      return Object.assign({}, state, newPayload)
    case SET_SECTION_TITLE:
      return Object.assign({}, state, { sectionTitle: payload })
    default:
      return state
  }
}
