import styled, {th} from '@xstyled/styled-components'

// No underline, on hover, underline sweeps in
export const SweepIn = styled.span`
  position: relative;
  white-space: nowrap;
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${props => th.color(props.hoverBGColor)};
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }
  &:hover{
    color: ${props => th.color(props.hoverColor)};
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`
// Underline, on hover, underline sweeps out
export const SweepOut = styled.span`
  position: relative;
  white-space: nowrap;
  ::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${props => th.color(props.hoverBGColor)};
    transform-origin: bottom left;
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    color: ${props => th.color(props.hoverColor)};
    &::before {
      transform-origin: bottom right;
      transform: scaleX(0);
    }
  }
`

const cssfxUnderline = {
  SweepIn,
  SweepOut
}

export default cssfxUnderline
