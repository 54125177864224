import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from '../lib/cache'

export const FETCH_TRENDS_LIST = 'api/FETCH_TRENDS_LIST'
export const FETCH_TRENDS_LIST_SUCCESS = 'api/FETCH_TRENDS_LIST_SUCCESS'
export const FETCH_TRENDS_LIST_ERROR = 'api/FETCH_TRENDS_LIST_ERROR'

const SERVER_TRENDS_URL = typeof window !== 'undefined' ? '/data/content/trends/iol' : process.env.RAZZLE_CONTENT + '/trends/iol'
const section = 'trends'
const cache = Cache()

async function fetchTrendsListApi () {
  const result = await cache.get(section)
  if (result) {
    return result
  }
  return axios.get(SERVER_TRENDS_URL)
    .then(response => {
      return cache.set(section, response.data)
    })
    .catch(err => {
      throw err
    })
}

function * fetchTrendsListSaga () {
  try {
    const res = yield call(fetchTrendsListApi)
    let payload = {}
    if (res) {
      payload = {
        lastFetch: new Date(),
        trends: res
      }
    }
    yield put({ type: FETCH_TRENDS_LIST_SUCCESS, payload })
  } catch (e) {
    yield put({ type: FETCH_TRENDS_LIST_ERROR, payload: e.message })
  }
}

export function * watchFetchTrendsList () {
  yield takeLatest(FETCH_TRENDS_LIST, fetchTrendsListSaga)
}

// Saga actions
export const fetchTrendsList = () => ({ type: FETCH_TRENDS_LIST })

const initialState = {
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  error: null,
  trends: []
}

export const Reducer = (state = initialState, { type, payload }) => {
  let newPayload = {}
  switch (type) {
    case FETCH_TRENDS_LIST:
      newPayload = Object.assign({}, state[section], {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
      return Object.assign({}, state, newPayload)
    case FETCH_TRENDS_LIST_ERROR:
      newPayload = Object.assign({}, state[section], {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
      return Object.assign({}, state, newPayload)
    case FETCH_TRENDS_LIST_SUCCESS:
      newPayload = Object.assign({}, state[section], {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        trends: payload.trends,
        lastFetch: payload.lastFetch
      })
      return Object.assign({}, state, newPayload)
    default:
      return state
  }
}
