import React, { useState, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocation } from 'react-router-dom'
import useHasMounted from '../../lib/useHasMounted'
import deepCompare from '../../lib/deepCompare'
import { adSlotRegistered, getPageType } from '../../store/app'
import { getSessionId, getVisitorId } from '../../store/article'
import IonAd from 'ion-ads'
import ionPostAnalytics from '../../lib/ionPostAnalytics'

const Ad = (props) => {
  const location = useLocation()
  const [path, setPath] = useState(location.pathname)
  const pathname = location.pathname
  const hasHydrated = useHasMounted()
  const onSlotRequested = event => {
    // Log the Ad Request event
    const locationUrl = window.location.href
    const referrerUrl = window.document.referrer
    const scrollDepth = window.scrollY
    const ad = {
      request: {
        slotId: event.slotId,
        targeting: event.slot.getTargetingKeys()?.map(key => key + ':' + event.slot.getTargeting(key)).join(','),
        path: event.slot.getAdUnitPath(),
        mappings: event.sizeMapping?.map(map => ({ width: map[0][0], height: map[0][1], sizes: map[1] })).filter(map => map.sizes.length),
        sizes: event.sizes?.map(map => ({ width: map[0], height: map[1] }))
      }
    }
    const _eventData = { referrerUrl, scrollDepth, ad }
    ionPostAnalytics('adRequest', getPageType(), props.visitorId, process.env.RAZZLE_TITLE_KEY, props.sessionId, locationUrl, _eventData)
  }
  const onSlotRendered = event => {
    // Log the Ad Request event
    const locationUrl = window.location.href
    const referrerUrl = window.document.referrer
    const scrollDepth = window.scrollY
    const ad = {
      response: event
    }
    const _eventData = { referrerUrl, scrollDepth, ad }
    ionPostAnalytics('adRender', getPageType(), props.visitorId, process.env.RAZZLE_TITLE_KEY, props.sessionId, locationUrl, _eventData)
    if (props.onSlotRenderEnded) {
      props.onSlotRenderEnded(event)
    }
  }
  useEffect(() => {
    if (location.pathname !== path) {
      setPath(location.pathname)
    }
  }, [pathname])
  if (!process.env.RAZZLE_ENABLE_GAM || path !== pathname || !hasHydrated) {
    return null
  }
  return (
    <IonAd
      path={`/${process.env.RAZZLE_DFP_CODE}${props.adPath || (path === '/' ? '/homepage' : path)}`}
      refreshTime={30}
      onSlotRenderEnded={onSlotRendered}
      {...props}
      onSlotRequested={onSlotRequested}
      onAdSlotRegistered={props.adSlotRegistered} />
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ adSlotRegistered }, dispatch)

export default memo(connect((state) => ({ app: state.app, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(Ad), deepCompare)
