import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const SUBSCRIBE = 'api/SUBSCRIBE'
export const SUBSCRIBE_SUCCESS = 'api/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_ERROR = 'api/SUBSCRIBE_ERROR'
export const REINITIALIZE = 'api/REINITIALIZE'
export const UPDATE_NEWSLETTER_LIST = 'api/UPDATE_NEWSLETTER_LIST'
export const ADD_NEWSLETTER_LIST_IDS = 'api/ADD_NEWSLETTER_LIST_IDS'

export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

export const subscribeAPI = (data) => {
  return axios.post(SERVER_URL + '/data/newsletter/subscribe', {
    contact_name: data.firstName,
    contact_lastname: data.lastName,
    email: data.email,
    lists: data.listIds,
    hp: '',
    error: ''
  })
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

const addNewsletterListIdsAPI = (state, listIds) => {
  const newState = Object.assign({}, state)
  newState.listIds = listIds.map((listId) => {
    if (!newState.listIds.includes(listId)) {
      return Array.concat(newState.listIds, [listId])
    } else {
      return newState.listIds
    }
  })
  return newState
}

const toggleNewsletterSubscriptionAPI = (state, listId) => {
  const newState = Object.assign({}, state)
  if (newState.listIds.includes(listId)) {
    // remove from the array
    newState.listIds = newState.listIds.filter((element) => {
      return element !== listId
    })
  } else {
    // add to the array ensuring a new array object is created
    newState.listIds = newState.listIds.concat([listId])
  }
  return newState
}

function * subscribeSaga ({ payload }) {
  try {
    yield call(subscribeAPI, payload)
    yield put({ type: SUBSCRIBE_SUCCESS })
  } catch (e) {
    console.error('ERROR while subscribing', e.message)
    yield put({ type: SUBSCRIBE_ERROR, payload: { message: e.message } })
  }
}

export function * watchSubscribe () {
  yield takeEvery(SUBSCRIBE, subscribeSaga)
}

// Saga actions
export const subscribeNewsletter = (firstName, lastName, email, listIds) => ({ type: SUBSCRIBE, payload: { firstName, lastName, email, listIds } })
export const toggleNewsletterSubscription = (listId) => ({ type: UPDATE_NEWSLETTER_LIST, payload: listId })
export const addNewsletterListIds = (listIds) => ({ type: ADD_NEWSLETTER_LIST_IDS, payload: listIds })
export const updateForm = (data) => ({ type: UPDATE_DATA, payload: data })
export const reinitialize = () => ({ type: REINITIALIZE, payload: {} })

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  listIds: ['UvMIi9m2RuP4MphsG52Vvw'],
  output: ''
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REINITIALIZE:
      return Object.assign({}, defaultState)
    case ADD_NEWSLETTER_LIST_IDS:
      return addNewsletterListIdsAPI(state, payload)
    case UPDATE_NEWSLETTER_LIST:
      return toggleNewsletterSubscriptionAPI(state, payload)
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case SUBSCRIBE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmitting: true,
        hasSubmitted: false,
        hasError: false,
        error: null,
        data: payload
      })
    case SUBSCRIBE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        isSubmitting: false,
        hasSubmitted: false,
        didInvalidate: true
      })
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: true,
        didInvalidate: false
      })
    default:
      return state
  }
}
