'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

// To prevent double measuring due to the script load, suppress the first call 
var firstCall = true;

var Chartbeat = function Chartbeat(events) {
  if (typeof window === 'undefined') {
    return;
  }
  if (firstCall) {
    firstCall = false;
    return;
  }
  if (typeof window.pSUPERFLY === 'undefined') {
    console.error('window.pSUPERFLY is not defined, Have you forgotten to include Chartbeat?');
  } else {
    events.forEach(function (event) {
      switch (event.event) {
        case 'VirtualPageview':
          if (!event.virtualPageURL.match('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}')) {
            window.pSUPERFLY.virtualPage({
              sections: '',
              authors: '',
              path: event.virtualPageURL,
              title: ''
            });
          }
          break;
        case 'ArticlePageview':
          window.pSUPERFLY.virtualPage({
            sections: event.section,
            authors: event.author,
            path: event.path,
            title: event.title
          });
          break;
        default:
          break;
      }
    });
  }
};

exports.default = Chartbeat;