import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {th, css, system} from '@xstyled/styled-components'

const transforms = {
  top: 'translateY(-100vh)',
  right: 'translateX(100vw)',
  bottom: 'translateY(100vh)',
  left: 'translateX(-100vw)'
}
const placements = {
  top: {
    top: 0,
    right: 0,
    left: 0
  },
  right: {
    top: 0,
    right: 0,
    bottom: 0
  },
  bottom: {
    right: 0,
    bottom: 0,
    left: 0
  },
  left: {
    top: 0,
    bottom: 0,
    left: 0
  }
}

//Styled Components
const DrawerWrapper = styled.div`
  display: block;
  ${props => css`
    width: ${(props.position !== 'top' && props.position !== 'bottom') && props.size ? props.size : '100%'};
    height: ${(props.position === 'top' || props.position === 'bottom') && props.size ? props.size : '100%'};
    transform: ${!props.open ? transforms[props.position] : null};
  `}
`;

//Covers entire view and is used for dismissal
const DrawerOverlay = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 998;
  display: ${props => props.open ? null : 'none' };
  ${th('drawers.backdrop')}
`;

// width: ${props => ((props.position !== 'top' && props.position !== 'bottom') && th('drawers.sizes.horizontal')[props.size]) ? th('drawers.sizes.horizontal')[props.size] : '300px'};
const DrawerContent = styled.div`
  display: block;
  box-sizing: border-box;
  position: fixed;
  ${props => placements[props.position]}
  z-index: 999;
  transform: ${props => !props.open ? transforms[props.position] : null};
  transition: transform 0.2s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
  color: #000;
  box-shadow: -10px 0px 10px rgba(0,0,0,0.19);
  width: 400px;
  ${th('drawers.content')}
  ${system}
`;
  // ${props => props.position === 'top' || props.position === 'bottom' ? props.theme.drawers.sizes.vertical[props.size] : ''}
  // ${props => props.position === 'left' || props.position === 'right' ? props.theme.drawers.sizes.horizontal[props.size] : ''}

export function useDrawer(options) {
  const open = options && !!options.open
  const [isOpen, setIsOpen] = useState(open)
  return {
    isOpen, 
    open: () => setIsOpen(true), 
    close: () => setIsOpen(false), 
    toggle: () => setIsOpen(!isOpen)
  }
}

export const Drawer = ({ isOpen, size, width, position, close, backgroundColor, children }) => {
  return (
    <DrawerWrapper open={isOpen} size={size} position={position}>
      <DrawerOverlay open={isOpen} onClick={close} />
      <DrawerContent open={isOpen} w={width} position={position}>
        {children}
      </DrawerContent>
    </DrawerWrapper>
  )
}

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  position: PropTypes.oneOf(['top','bottom','left','right']),
  onDismiss: PropTypes.func
}

Drawer.defaultProps = {
  isOpen: false,
  position: 'left',
  width: '100%'
}

export default Drawer
