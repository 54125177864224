import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from 'ion-cache'

export const FETCH_STATICPAGE = 'api/FETCH_STATICPAGE'
export const FETCH_STATICPAGE_SUCCESS = 'api/FETCH_STATICPAGE_SUCCESS'
export const FETCH_STATICPAGE_ERROR = 'api/FETCH_STATICPAGE_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const cache = new Cache()

const fetchStaticPageAPI = (pageName) => {
  return cache.get(pageName)
    .then(result => {
      if (!result) {
        return axios.get(SERVER_URL + '/data/static/' + pageName)
          .then(response => {
            return cache.set(pageName, response.data, 60 * 60000) // Cache for 60 minutes
          })
          .catch(err => {
            // console.error(err)
            throw err
          })
      }
      return result
    })
}

function * fetchStaticPageSaga ({ payload }) {
  try {
    const res = yield call(fetchStaticPageAPI, payload)
    yield put({ type: FETCH_STATICPAGE_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_STATICPAGE_ERROR, payload: e.message })
  }
}

export function * watchFetchStaticPage () {
  yield takeLatest(FETCH_STATICPAGE, fetchStaticPageSaga)
}

// Saga actions
export const fetchStaticPage = (pageName) => ({ type: FETCH_STATICPAGE, payload: pageName })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  canonical: null,
  error: null,
  pageName: '',
  staticPage: null // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_STATICPAGE:
      return Object.assign({}, state, {
        pageName: payload,
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        canonical: null,
        error: null
      })
    case FETCH_STATICPAGE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        canonical: null
      })
    case FETCH_STATICPAGE_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        staticPage: payload,
        hasError: false,
        canonical: null,
        error: null
      })
    default:
      return state
  }
}
