import React, { useState, useEffect, useRef } from 'react'
import { x } from '@xstyled/styled-components'
import axios from 'axios'

import { useCookie, isBrowser } from './ui/utils'
import useHasMounted from '../../lib/useHasMounted'
import { getRegistration, urlB64ToUint8Array } from '../ServiceWorker'
import Popup from 'ion-popup'
import iolBlocks from '../../../assets/images/general/push-notifications.svg'
import StyledLink from './ui/Link'

const PushPrompt = () => {
  if (!isBrowser || !('PushManager' in window)) {
    return null
  }
  return <PrivatePushPrompt />
}

const PrivatePushPrompt = (props) => {
  const hasHydrated = useHasMounted()
  const [userHasVisited] = useCookie('UserVisited', '0')
  const [promptLater, setPromptLater] = useCookie('PromptLater', false)
  const [havePrompted, setPrompted] = useState(true)
  const [helpPopupVisible, setHelpPopupVisible] = useState(false)
  const subscription = useRef(false)
  const clickSource = 'Push Prompt'

  const registration = getRegistration()
  useEffect(() => {
    if (registration && registration.pushManager) {
      registration.pushManager.getSubscription()
        .then(sub => {
          subscription.current = sub
          if (!sub && userHasVisited && promptLater !== 'true') {
            setPrompted(false)
          } else {
            setPrompted(true)
          }
        })
        .catch((err) => {
          console.error(`Error during getSubscription(): ${err}`)
        })
    }
  }, [registration])

  const handleLater = React.useCallback(()=>{
    setPromptLater(true, {days: 7})
    setPrompted(true)
  })
  const handleAllow = React.useCallback(() => {
    setPromptLater(true, {days: 7})
    setPrompted(true)
    if (!subscription.current && registration && registration.pushManager) {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(process.env.RAZZLE_SERVERKEY)
      })
        .then(function (sub) {
          console.log('User IS now subscribed.')
          subscription.current = sub
          return axios.post(process.env.RAZZLE_PUSH_SERVICE + '/register', JSON.stringify({ subscription: sub, titleKey: process.env.RAZZLE_TITLE_KEY }), {
            headers: {
              'Content-type': 'application/json'
            }
          })
        })
        .then(res => {
          setPrompted(true)
        })
        .catch(function (err) {
          console.log('Failed to subscribe the user: ', err)
          setHelpPopupVisible(true)
          subscription.current = false
        })
    }
  })

  if (!hasHydrated || (promptLater === 'true') || (userHasVisited !== '1')) {
    return null
  }
  if (havePrompted && !helpPopupVisible) {
    return null
  }
  return (
    <>
      {helpPopupVisible &&
        <Popup visible>
          <x.div display='grid' gridTemplateColumns='56px auto' gap={20}>
            <x.div>
              <x.img src={iolBlocks} alt='IOL' w={56} />
            </x.div>
            <x.div color='grey'>
              <x.p text='xlarge' fontWeight='medium'>Small problem..</x.p>
              <x.p mt={5}>You have previously chosen to block notifications.</x.p>
              <x.p mb={15}>To follow stories requires you to enable notifications in your browser settings.</x.p>
              <x.div display='flex' justifyContent='flex-end'>
                <StyledLink onClick={() => setHelpPopupVisible(false)} to={'/help'} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} source={clickSource}><x.p mt={3} mr={15}>Help</x.p></StyledLink>
                <x.button onClick={() => setHelpPopupVisible(false)} bg='#2E5FDC' color='white' p='6px 20px' borderRadius={4} ml={5}>OK</x.button>
              </x.div>
            </x.div>
          </x.div>
        </Popup>
      }
      {!helpPopupVisible && 
        <Popup visible>
          <x.div display='grid' gridTemplateColumns='56px auto' gap={20}>
            <x.div>
              <x.img src={iolBlocks} alt='IOL' w={56} />
            </x.div>
            <x.div color='grey'>
              <x.p mt={5}>Would you like to receive notifications for breaking news?</x.p>
              <x.p mb={15}>You can turn these off at any time.</x.p>
              <x.div display='flex' justifyContent='flex-end'>
                <x.button onClick={handleLater} bg='#FFFFFF' color='grey' p='6px 20px' borderRadius={4}>Later</x.button>
                <x.button onClick={handleAllow} bg='#2E5FDC' color='white' p='6px 20px' borderRadius={4} ml={5}>Allow</x.button>
              </x.div>
            </x.div>
          </x.div>
        </Popup>
      }
    </>
  )
}

export default PushPrompt
