import { useState } from 'react'
import { x, useDown } from '@xstyled/styled-components'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import useScrollDirection from '../../lib/useScrollDirection'

import CommercialNav from './CommercialNav'
import SiteHeader from './SiteHeader'
import NavBar from './NavBar'
import MobileHeader from './MobileHeader'
import MobileArticleHeader from './MobileArticleHeader'
import HamburgerNav from './HamburgerNav'
import { getSessionId, getVisitorId } from '../../store/article'

export function Header (props) {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const isMobile = useDown('md') || (typeof window === 'undefined')
  const location = useLocation()
  const scrollDirection = useScrollDirection({ topDelay: 150 })
  const isArticlePage = !!location.pathname.match(/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})|([0-9]{5,})$/)
  return (
    <x.header
      minHeight={82}
      position={ props.wallpaperActive ? 'static' : 'fixed'} top={props.wallpaperActive ? '0px' : scrollDirection === 'up' ? '0px' : '-100%'} bg='white' 
      w={props.wallpaperActive ? '1220px' : '100%'} transitionProperty={props.wallpaperActive ? 'none' : 'all'} 
      zIndex={props.wallpaperActive ? 'auto': '11'}
      mx='auto' transitionTimingFunction='cubic-bezier(0.4, 0, 0.2, 1)' transitionDuration='800ms'
    >
      <MobileArticleHeader article={props.article} visible={isMobile && isArticlePage} isNavOpen={isNavOpen}>
        <HamburgerNav prefix='article' toggleScrolling={props.toggleScrolling} setIsNavOpen={setIsNavOpen} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} />
      </MobileArticleHeader>
      <MobileHeader section={location.pathname.replace(/\//, '')} titleLabel={props.sectionTitle} visible={isMobile && !isArticlePage} isNavOpen={isNavOpen} visitorId={props.visitorId} sessionId={props.sessionId}>
        <HamburgerNav prefix='mobile' toggleScrolling={props.toggleScrolling} setIsNavOpen={setIsNavOpen} visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} />
      </MobileHeader>
      <x.div visibility={isMobile ? 'hidden' : 'visible'}>
        <CommercialNav />
        <SiteHeader visitorId={props.visitorId} sessionId={props.sessionId} section={props.section} />
        <NavBar wallpaperActive={props.wallpaperActive} section={props.section} />
      </x.div>
    </x.header>
  )
}

const mapStateToProps = (state) => ({ ...state.article, ...state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({})
export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev, nextOwnProps, prevOwnProps) => {
    return (prev.article.article === next.article.article) && (prev.sectionTitle === next.sectionTitle)
  }
})(Header)
