import Urlify from 'urlify'

const urlify = Urlify.create({
  addEToUmlauts: true,
  szToSs: true,
  spaces: '-',
  nonPrintable: '',
  toLower: true,
  trim: false
})

const ImageSizes = {
  hero: 560,
  featured: 800,
  primary: 240,
  slider: 275,
  normal: 100,
  push: 80
}

export function getCanonical (article) {
  if (!article.headline) {
    return article.contentKey
  }
  return article.section + '/' + urlify(article.headline.replace(/&/g, '-and-')).replace(/-+/g, '-') + '-' + article.contentKey
}

export function getImageSize (size) {
  const ImageSize = ImageSizes[size] || size
  return ImageSize
}

export function getImage (article) {
  const Image = article.image || (article.images && article.images[0]) || (article.videos && { url: (article.videos[0].thumbnailURL?.url || article.videos[0].thumbnail.url) })
  return Image
}
/**
 * Returns the teaser headline to be used in article cards, social shares etc.
 *
 * @param {*} article An article to retrieve the headline from
 * @returns The headline to use
 */
export function getTeaser (article) {
  return article.teaserHeadline || article.headline
}
