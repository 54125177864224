"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Basic Caching implementation. 
 * This implementation will be used for server environments
 */

var Cache = function () {
  function Cache(options) {
    _classCallCheck(this, Cache);

    this._ttl = 5 * 60000; // Defaults to 5 minutes
    if (options) {
      if (options.ttl) {
        this.ttl = options.ttl;
      }
    }
  }
  //Setting a value in the cache


  _createClass(Cache, [{
    key: "set",
    value: async function set(key, value, ttl) {
      return Promise.resolve(value);
    }
    //Delete a key from the cache

  }, {
    key: "delete",
    value: async function _delete(key) {
      return true;
    }
    //Setting a value in the cache

  }, {
    key: "get",
    value: async function get(key) {
      return false;
    }
  }]);

  return Cache;
}();

exports.default = Cache;