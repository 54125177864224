import { useState, useLayoutEffect, useEffect, useRef } from 'react'

export const isBrowser = typeof window !== 'undefined';
export const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect

export const setCookie = (name, value, options) => {
  if (!isBrowser) return;

  const optionsWithDefaults = {
    days: 0,
    path: '/',
    ...options,
  };

  if (optionsWithDefaults.days) {
    const expires = new Date(
      Date.now() + optionsWithDefaults.days * 864e5
    ).toUTCString();

    document.cookie =
      name +
      '=' +
      encodeURIComponent(value) +
      '; expires=' +
      expires +
      '; path=' +
      optionsWithDefaults.path;
  } else if (optionsWithDefaults.seconds) {
    document.cookie =
      name +
      '=' +
      encodeURIComponent(value) +
      '; max-age=' +
      optionsWithDefaults.seconds +
      '; path=' +
      optionsWithDefaults.path;
  } else {
    document.cookie =
      name +
      '=' +
      encodeURIComponent(value) +
      '; path=' +
      optionsWithDefaults.path;
  }
};

export const getCookie = (name, initialValue = '') => {
  return (
    (isBrowser &&
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '')) ||
    initialValue
  );
};

export function useCookie(key, initialValue) {
  const [item, setItem] = useState(() => {
    return getCookie(key, initialValue);
  });

  const updateItem = (value, options) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
}

export function getScrollPosition({ element, useWindow }) {
  if (!isBrowser || !element.current) {
    return { x: 0, y: 0 }
  }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}