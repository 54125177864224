'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.url2section = url2section;
exports.articleUrl2section = articleUrl2section;
exports.getSectionLabel = getSectionLabel;
exports.getBaseSectionLabel = getBaseSectionLabel;

/**
 * Formats an url to a section
 * @example
 * // returns "news/south-africa"
 *  url2section("/news/south-africa/")
 * @param {string} url
 */
function url2section(url) {
  return url.replace(/^\//, '').replace(/\/$/, '');
}

/**
 * Formats an article url to a section
 * @example
 * // returns "news/south-africa"
 *  url2section("/news/south-africa/zuma-must-fall-1235576")
 * @param {string} url
 */
function articleUrl2section(url) {
  return url.replace(/^\//, '').replace(/\/[^\/]*$/, '');
}

/**
 * Formats the current section in a human readable way
 * @example
 * // returns "South Africa"
 *  getSectionLabel("news/south-africa")
 * @param {string} sectionId
 */
function getSectionLabel(sectionId) {
  var section = sectionId.split('/').pop(); // Grab only the last section
  return toTitleCase(section.replace(/-/g, ' ').toLowerCase());
}

/**
 * Formats the first segment of the current path in a human readable way
 * @example
 * // returns "News"
 *  getBaseSectionLabel("news/south-africa")
 * @param {string} urlPath
 */
function getBaseSectionLabel(urlPath) {
  var section = urlPath.split('/')[0]; // Grab only the first segment of the section
  return toTitleCase(section.replace('-', ' ').toLowerCase());
}

// Shamelessly ripped from 
// https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}