'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _cache = require('./cache');

var _cache2 = _interopRequireDefault(_cache);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Basic Caching implementation. 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This implementation will be used for client environments
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */


var MemoryCache = function (_Cache) {
  _inherits(MemoryCache, _Cache);

  function MemoryCache(options) {
    _classCallCheck(this, MemoryCache);

    var _this = _possibleConstructorReturn(this, (MemoryCache.__proto__ || Object.getPrototypeOf(MemoryCache)).call(this, options));

    _this._store = {};
    return _this;
  }
  //Setting a value in the cache


  _createClass(MemoryCache, [{
    key: 'set',
    value: async function set(key, value, ttl) {
      if (!ttl) {
        ttl = this._ttl;
      }
      this._store[key] = { value: value, expires: new Date().getTime() + ttl };
      this.clean();
      return Promise.resolve(value);
    }
    //Cycles through all keys and removes expired items

  }, {
    key: 'clean',
    value: async function clean() {
      var _this2 = this;

      var now = new Date().getTime();
      var promises = Object.keys(this._store).map(function (key) {
        if (now > _this2._store[key].expires) {
          return _this2.delete(key);
        }
      });
      return Promise.resolve(promises);
    }
    //Delete a key from the cache

  }, {
    key: 'delete',
    value: async function _delete(key) {
      delete this._store[key];
      return Promise.resolve(true);
    }
    //Setting a value in the cache

  }, {
    key: 'get',
    value: async function get(key) {
      this.clean();
      var value = this._store[key];
      if (value) {
        return Promise.resolve(value.value);
      }
      // this.delete(key)
      return Promise.resolve(false);
    }
  }]);

  return MemoryCache;
}(_cache2.default);

exports.default = MemoryCache;