
import { takeLatest, takeEvery, put, select, delay, call, spawn } from 'redux-saga/effects'
import { clearSlots, performSingleRequest } from 'ion-ads'
import ionPostAnalytics from '../lib/ionPostAnalytics'
import { NAVIGATE_TO } from 'ion-navchevron'
import { getAllArticles, FETCH_ARTICLES_SUCCESS } from '../store/articles'

export const HIDE_MENU = 'HIDE_MENU'
export const SHOW_MENU = 'SHOW_MENU'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const HIDE_SEARCH = 'HIDE_SEARCH'
export const SHOW_SEARCH = 'SHOW_SEARCH'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'
export const EXPERIMENT_WIN = 'EXPERIMENT_WIN'
export const PAGE_VIEW = 'PAGE_VIEW'
export const ERROR_PAGE_VIEW = 'ERROR_PAGE_VIEW'
export const HOME_PAGE_VIEW = 'HOME_PAGE_VIEW'
export const SECTION_PAGE_VIEW = 'SECTION_PAGE_VIEW'
export const STATIC_PAGE_VIEW = 'STATIC_PAGE_VIEW'
export const UPDATE_CONNECTIVITY = 'UPDATE_CONNECTIVITY'
export const STATS_PING = 'STATS_PING'
export const RESET_FIRST_RENDER = 'RESET_FIRST_RENDER'
export const SELECT_MOST_READ_TAB = 'SELECT_MOST_READ_TAB'
export const AD_BLOCKER = 'AD_BLOCKER'
export const RENDER_BLOCK = 'RENDER_BLOCK'
export const UPDATE_WALLPAPER_ACTIVE = 'UPDATE_WALLPAPER_ACTIVE'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const AD_SLOT_REGISTERED = 'AD_SLOT_REGISTERED'
export const PREFETCH = 'PREFETCH'

export const hideMenu = () => ({ type: HIDE_MENU, payload: false })
export const showMenu = () => ({ type: SHOW_MENU, payload: true })
export const toggleMenu = () => ({ type: TOGGLE_MENU })

export const hideSearch = () => ({ type: HIDE_SEARCH, payload: false })
export const showSearch = () => ({ type: SHOW_SEARCH, payload: true })
export const toggleSearch = () => ({ type: TOGGLE_SEARCH })

export const adBlockDetected = (path) => ({ type: AD_BLOCKER, payload: path })
export const renderBlock = () => ({ type: RENDER_BLOCK })
export const adSlotRegistered = (slotId) => ({ type: AD_SLOT_REGISTERED, slotId })

export const updateConnectivity = (isConnected) => ({ type: UPDATE_CONNECTIVITY, payload: isConnected })
export const setSubscription = (subscription) => ({ type: SET_SUBSCRIPTION, payload: subscription })

export const experimentWin = (experiment, variant) => ({ type: EXPERIMENT_WIN, payload: experiment, variant })
export const pageView = (path, visitorId, sessionId) => ({ type: PAGE_VIEW, payload: path, visitorId, sessionId })
export const errorPageView = (path, visitorId, sessionId) => ({ type: ERROR_PAGE_VIEW, payload: path, visitorId, sessionId })
export const homePageView = (path, visitorId, sessionId, firstRender) => ({ type: HOME_PAGE_VIEW, payload: path, visitorId, sessionId, firstRender })
export const sectionPageView = (path, section, visitorId, sessionId, firstRender) => ({ type: SECTION_PAGE_VIEW, payload: { path, section }, visitorId, sessionId, firstRender })
export const staticPageView = (path, visitorId, sessionId, firstRender) => ({ type: STATIC_PAGE_VIEW, payload: path, visitorId, sessionId, firstRender })
export const isConnected = state => state.app.isConnected

export const setMostReadTab = (mostReadTab) => ({ type: SELECT_MOST_READ_TAB, payload: mostReadTab })
export const setWallPaperActive = (active) => ({ type: UPDATE_WALLPAPER_ACTIVE, payload: active })

export const Prefetch = () => ({ type: PREFETCH })

function * prefetchSaga () {
  const articles = yield select(getAllArticles)
  for (const section in articles) {
    yield put({ type: FETCH_ARTICLES_SUCCESS, payload: articles[section], section })
  }
}

export function * watchPrecache () {
  yield takeEvery(PREFETCH, prefetchSaga)
}

function * transitionTo (path) {
  // When navigating to a new location, destroy all the current slots
  clearSlots()

  // Find an element in the DOM with the ID of GenecyDFPAdWallpaperCont and delete it if it exists
  // Run this only while we're on a browser
  if (typeof window !== 'undefined') {
    const wallpaperCont = document.getElementById('GenecyDFPAdWallpaperCont')
    if (wallpaperCont) {
      wallpaperCont.parentNode.removeChild(wallpaperCont)
    }
  }

  yield put({ type: UPDATE_WALLPAPER_ACTIVE, payload: false })
}

function * hideMenuSaga () {
  yield put({ type: HIDE_MENU, payload: false })
}

function * pingSaga () {
  if (typeof window !== 'undefined') {
    while (true) {
      yield delay(5000)
      // yield put({type: STATS_PING})
      yield call(recordPingToStats)
    }
  }
}

function * showLastSlotRegistered ({ slotId }) {
  // yield delay(2000) - seems to give stable results, but is very long
  // Make it 100ms and YOLO
  yield delay(100)
  performSingleRequest(slotId)
}

function * recordPingToStats () {
  const locationUrl = window.location.href
  const referrerUrl = window.document.referrer
  const scrollDepth = window.scrollY
  const state = yield select()
  yield call(ionPostAnalytics, 'ping', getPageType(), state.user.visitorId, process.env.RAZZLE_TITLE_KEY, state.user.sessionId, locationUrl, { referrerUrl, scrollDepth })
}

export function * watchNavChanges () {
  yield spawn(pingSaga)
  yield takeLatest(PAGE_VIEW, transitionTo)
  yield takeLatest(NAVIGATE_TO, hideMenuSaga)
  yield takeLatest(AD_SLOT_REGISTERED, showLastSlotRegistered)
  yield takeLatest(STATS_PING, recordPingToStats)
}

let _pageType = 'default'

export function setPageType (pageType) {
  _pageType = pageType
}

export function getPageType () {
  return _pageType
}

const defaultState = {
  showMenu: false,
  showSearch: false,
  isConnected: true,
  adBlockerDetected: false,
  renderBlock: false,
  firstRender: true,
  path: '',
  mostReadTab: 0,
  wallpaperActive: false
}

export const Reducer = (state = defaultState, { type, payload, visitorId, sessionId }) => {
  switch (type) {
    case SET_SUBSCRIPTION:
      return { ...state, ...{ subscription: (payload === false ? false : JSON.stringify(payload)) } }
    case UPDATE_WALLPAPER_ACTIVE:
      return { ...state, ...{ wallpaperActive: payload } }
    case UPDATE_CONNECTIVITY:
      return { ...state, ...{ isConnected: payload } }
    case EXPERIMENT_WIN:
      return { ...state, ...{ experiment: payload.experiment, variant: payload.variant } }
    case PAGE_VIEW:
      return { ...state, ...{ path: payload, visitorId, sessionId } }
    case HIDE_SEARCH:
    case SHOW_SEARCH:
      return { ...state, ...{ showSearch: payload } }
    case TOGGLE_SEARCH:
      return { ...state, ...{ showSearch: !state.showSearch } }
    case RESET_FIRST_RENDER:
      return { ...state, ...{ firstRender: false } }
    case SHOW_MENU:
    case HIDE_MENU:
      return { ...state, ...{ showMenu: payload } }
    case TOGGLE_MENU:
      return { ...state, ...{ showMenu: !state.showMenu } }
    case SELECT_MOST_READ_TAB:
      return { ...state, ...{ mostReadTab: payload } }
    case AD_BLOCKER:
      return { ...state, ...{ adBlockerDetected: true, path: payload } }
    case RENDER_BLOCK:
      return { ...state, ...{ renderBlock: true } }
    default:
      return state
  }
}
