'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.BetaTimestamps = BetaTimestamps;
exports.formatAgo = formatAgo;
exports.getDate = getDate;
exports.formatDate = formatDate;
exports.parse = parse;

var logger = console;

var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function BetaTimestamps(timestamp, defaultValue) {
  if (isNaN(timestamp)) {
    // logger.error('formatAgo', timestamp)
    return defaultValue;
  }
  var now = new Date().getTime();
  var duration = now - timestamp;
  duration = Math.floor(duration / 1000);
  if (duration <= 1) {
    return '1s ago';
  }
  if (duration < 60) {
    return duration + 's ago';
  }
  duration = Math.floor(duration / 60);
  if (duration <= 1) {
    return '1m ago';
  }
  if (duration < 60) {
    return duration + 'm ago';
  }
  // if (duration < 120) {
  //   return `${duration}m`;
  // }
  duration = Math.floor(duration / 60);
  if (duration <= 1) {
    return '1h ago';
  }
  if (duration < 4) {
    return duration + 'h ago';
  }
  if (duration <= 24) {
    return duration + 'h ago';
  }
  //   duration = Math.floor(duration / 24);
  //   if (duration <= 1) {
  //      return '1d ago';
  //   }
  //   if (duration < 60) {
  //      return `${duration}d ago`;
  //   }
  return defaultValue;
}

function formatAgo(date) {
  if (!date) {
    // logger.error('formatAgo empty')
  } else if (!isNaN(date)) {
    return BetaTimestamps(date, formatDate(date, false));
  } else if (typeof date === 'string') {
    var timestamp = void 0;
    if (date.length < 16) {
      // logger.warn('formatAgo string', date)
      return date;
    } else if (date.length === 16) {
      timestamp = Date.parse(date + 'Z');
    } else {
      timestamp = Date.parse(date);
    }
    // logger.debug('formatAgo', date, BetaTimestamps.formatAgo(timestamp, formatDate(date, false)))
    return BetaTimestamps(timestamp, formatDate(date, false));
  } else {
    // logger.error('formatAgo type', typeof date)
  }
}

function getDate(date) {
  if (Number.isInteger(date)) {
    return new Date(date);
  } else if (typeof date === 'string') {
    if (date.length === 16) {
      return new Date(date + 'Z');
    } else {
      // logger.warn('formatDate length', date)
      return new Date(date);
    }
  } else if ((typeof date === 'undefined' ? 'undefined' : _typeof(date)) === 'object') {
    return date;
  } else {
    logger.warn('formatDate', typeof date === 'undefined' ? 'undefined' : _typeof(date));
  }
}

function formatDate(date, timeStamp) {
  if (Number.isInteger(date)) {
    return formatDateString(new Date(date).toString(), timeStamp);
  } else if (typeof date === 'string') {
    if (date.substr(date.length - 1) === 'Z') {
      return formatDateString(new Date(date).toString(), timeStamp);
    } else if (date.length === 16) {
      return formatDateString(new Date(date + 'Z').toString(), timeStamp);
    } else {
      logger.warn('formatDate', date);
    }
  } else if ((typeof date === 'undefined' ? 'undefined' : _typeof(date)) === 'object') {
    return formatDateString(date.toString(), timeStamp);
  } else {
    logger.warn('formatDate', typeof date === 'undefined' ? 'undefined' : _typeof(date));
  }
}

function formatDateString(string, timeStamp) {
  if (string.length >= 21) {
    var dd = string.substring(8, 10);
    var mmm = string.substring(4, 7);
    var yyyy = string.substring(11, 15);
    var hh = string.substring(16, 18);
    var mm = string.substring(19, 21);
    var monthName = getFullMonthName(mmm);
    if (parseInt(dd) < 1 || parseInt(dd) > 31 || parseInt(yyyy) < 2000 || parseInt(yyyy) > 2020 || !monthName) {
      // logger.warn('formatDateString', string, dd, mmm, yyyy)
      return string;
    }
    if (dd.substring(0, 1) === '0') {
      dd = dd.substring(1);
    }
    var dateString = dd + ' ' + monthName + ' ' + yyyy;
    if (timeStamp) {
      if (hh > 12) {
        hh -= 12;
        dateString += ', ' + hh + ':' + mm + 'pm';
      } else if (parseInt(hh) === 12) {
        dateString += ', ' + hh + ':' + mm + 'pm';
      } else {
        dateString += ', ' + hh + ':' + mm + 'am';
      }
    }
    return dateString;
  } else {
    // logger.warn('formatDateString', string)
    return string;
  }
}

function getFullMonthName(string) {
  return monthNames.filter(function (name) {
    return name.substr(0, string.length) === string;
  });
}

function parse(string) {
  if (typeof string === 'string') {
    if (string.length === 16) {
      return Date.parse(string + 'Z');
    } else {
      return Date.parse(string);
    }
  }
}