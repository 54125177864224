import React, { useCallback, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Popup from 'ion-popup'
import { x, useDown } from '@xstyled/styled-components'
import StyledLink from './../components/ui/Link'
import { subscribeNewsletter, updateForm, reinitialize, toggleNewsletterSubscription } from '../../store/subscribe'
import closeBtn from '../../../assets/images/icons/close-btn.svg'
import { getSessionId, getVisitorId } from '../../store/article'

function handleChange (event, updateForm) {
  const newState = {}
  newState[event.target.name] = event.target.value
  updateForm(newState)
}

function handleCheckboxChange (event, toggleNewsletterSubscription) {
  toggleNewsletterSubscription(event.target.value)
}

function validateEmail (email) {
  const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
  return email.match(re)
}

function validateName (name) {
  const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
  return name.match(re)
}

function verifyCallback (updateForm) {
  updateForm({ captchaVerified: true })
}

function handleSubscribe (event, { email, firstName, lastName, listIds, captchaVerified, updateForm, subscribeNewsletter }) {
  event.preventDefault()
  if (!validateEmail(email)) {
    updateForm({ output: 'Please enter a valid email address' })
    return false
  } else if (firstName && !validateName(firstName)) {
    updateForm({ output: 'Please enter a valid first name' })
    return false
  } else if (lastName && !validateName(lastName)) {
    updateForm({ output: 'Please enter a valid last name' })
    return false
  } else if (listIds.length < 1) {
    updateForm({ output: 'Please select at least one newsletter' })
    return false
  } else if (!captchaVerified) {
    updateForm({ output: 'Please enter a Captcha to verify that you are not a robot' })
    return false
  }
  subscribeNewsletter(firstName, lastName, email, listIds)
  return true
}

const NewsletterSubscribeCallToActionCmp = (props) => {
  const isMobile = useDown('md')
  return (
    <>
      {(!props.isSubmitting && !props.hasSubmitted) &&
        <x.div w={isMobile ? '100%' : '50%'} maxWidth={1220} mx='auto' background='#000000' p={20} textAlign='center' borderRadius={5} color='white'>
          <x.h2 text='h2' mb={20}>Subscribe and Win</x.h2>
          <x.form display='flex' flexDirection='column' alignItems='center' id='item-form' name='item-form' encType='text/plain' onSubmit={(event) => handleSubscribe(event, { ...props })}>
            <x.p fontSize={16} mb={20}>Subscribe to our daily newsletter to get your news delivered directly to your inbox and win your share of R5000!</x.p>
            <x.p fontSize={16} mb={20}>T's and C's apply</x.p>
            <x.input width='100%' p={10} mb={10} border='1px solid #ccc' borderRadius={5} id='email' placeholder='email@domain.com' required='required' type='email' name='email' value={props.email} onChange={(event) => handleChange(event, props.updateForm)} />
            <input id='UvMIi9m2RuP4MphsG52Vvw' type='hidden' value='UvMIi9m2RuP4MphsG52Vvw' />
            <x.div mb={10}>
              <Recaptcha sitekey='6LfsLz4cAAAAALqO3FpVjEBCqOp-wBHVF9qJXuWn' onChange={() => verifyCallback(props.updateForm)} />
            </x.div>
            {(props.output !== '' || props.error) &&
              <x.p mt={10}>Error: {props.output || props.error}</x.p>}
            <x.input backgroundColor={{ _: '#b41400', hover: '#333' }} color='white' border='none' padding='10px 20px' borderRadius={5} fontSize={18} cursor='pointer' transition='background-color 0.3s ease' id='submitbutton' type='submit' value='SUBSCRIBE' />
            <input type='hidden' name='form_hash' value='n1opdyilO0bEvwBq' id='form_hash' />
          </x.form>
        </x.div>}
      {props.isSubmitting &&
        <x.div w='100%' maxWidth={1220} mx='auto' mb={50}>
          <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Please wait</x.h1>
          <x.p>Submitting your subscription request</x.p>
        </x.div>}
      {props.hasSubmitted &&
        <x.div w='100%' maxWidth={1220} mx='auto' mb={50}>
          <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Thank you for your subscription</x.h1>
          <x.p>Please check your email inbox to verify your subscription.</x.p>
        </x.div>}
    </>
  )
}

const NewsletterSubscribePopup = (props) => {
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(props.isVisible)
  const isMobile = useDown('md')
  const clickSource = 'Newsletter Popup'

  const handleSubmit = useCallback((event) => {
    if (handleSubscribe(event, { ...props }) && props.onSubscribed) {
      props.onSubscribed(props)
    }
  })
  if (isMobile && !isSubscribePopupOpen) {
    return null
  }
  return (
    <>
      {!isMobile &&
        <StyledLink to='/newsletters' onClick={(event) => { setIsSubscribePopupOpen(!isSubscribePopupOpen); event.preventDefault(); }} color='white' fontWeight='medium' py={6} px={10} bg={{ _: 'red', hover: 'grey' }} mr={5} textTransform='uppercase' visitorId={props.visitorId} sessionId={props.sessionId} source={clickSource} section={props.section}>Subscribe</StyledLink>}
      {isSubscribePopupOpen &&
        <Popup visible={isSubscribePopupOpen}>
          <x.button aria-label='Close newsletter subscribe' onClick={() => setIsSubscribePopupOpen(false)} backgroundColor='white' p={5} top={0} right={0} display='flex' alignItems='center' justifyContent='flex-end' w='100%'>
            <x.img src={closeBtn} />
          </x.button>
          {(!props.isSubmitting && !props.hasSubmitted) &&
            <x.div w='100%' maxWidth={1220} mx='auto' mb={50} mt={-40}>
              <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Newsletter</x.h1>
              <x.form maxWidth={550} display='flex' flexDirection='column' id='item-form' name='item-form' encType='text/plain' onSubmit={(event) => handleSubmit(event, { ...props })}>
                <x.p text='small' mb={15}>{props.text || 'Get your news on the go. Subscribe to our newsletter below.'}</x.p>
                <x.input mb={15} bg='ltGrey' p={10} text='medium' id='firstName' placeholder='First name' required='required' type='text' name='firstName' value={props.firstName} onChange={(event) => handleChange(event, props.updateForm)} />
                <x.input mb={15} bg='ltGrey' p={10} text='medium' id='lastName' placeholder='Last name' required='required' type='text' name='lastName' value={props.lastname} onChange={(event) => handleChange(event, props.updateForm)} />
                <x.input mb={15} bg='ltGrey' p={10} text='medium' id='email' placeholder='email@domain.com' required='required' type='email' name='email' value={props.email} onChange={(event) => handleChange(event, props.updateForm)} />
                <x.div className='checkboxes' display='grid' gridTemplateColumns='repeat(2, calc((100% - 20px) / 2))' gap='0 20px'>
                  <input id='UvMIi9m2RuP4MphsG52Vvw' type='checkbox' value='UvMIi9m2RuP4MphsG52Vvw' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('UvMIi9m2RuP4MphsG52Vvw')} /><label htmlFor='UvMIi9m2RuP4MphsG52Vvw'><span />IOL Daily</label>
                  <input id='OJ4zwxCfnDwA9iw1aRjXdg' type='checkbox' value='OJ4zwxCfnDwA9iw1aRjXdg' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('OJ4zwxCfnDwA9iw1aRjXdg')} /><label htmlFor='OJ4zwxCfnDwA9iw1aRjXdg'><span />Business Report</label>
                  <input id='D6JzNYvmEh2D8763k8hPHlcA' type='checkbox' value='D6JzNYvmEh2D8763k8hPHlcA' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('D6JzNYvmEh2D8763k8hPHlcA')} /><label htmlFor='D6JzNYvmEh2D8763k8hPHlcA'><span />Sport</label>
                  <input id='yxHoP7IZr8gCEU1gsy7Ppg' type='checkbox' value='yxHoP7IZr8gCEU1gsy7Ppg' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('yxHoP7IZr8gCEU1gsy7Ppg')} /><label htmlFor='yxHoP7IZr8gCEU1gsy7Ppg'><span />Lifestyle</label>
                  <input id='FsPwab7kNQUCR1fO2edIZA' type='checkbox' value='FsPwab7kNQUCR1fO2edIZA' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('FsPwab7kNQUCR1fO2edIZA')} /><label htmlFor='FsPwab7kNQUCR1fO2edIZA'><span />Motoring</label>
                  <input id='UiIQSJPTxuu0RwnQs7632NQg' type='checkbox' value='UiIQSJPTxuu0RwnQs7632NQg' onChange={(event) => handleCheckboxChange(event, props.toggleNewsletterSubscription)} checked={props.listIds.includes('UiIQSJPTxuu0RwnQs7632NQg')} /><label htmlFor='UiIQSJPTxuu0RwnQs7632NQg'><span />Entertainment</label>
                </x.div>
                <Recaptcha sitekey='6LfsLz4cAAAAALqO3FpVjEBCqOp-wBHVF9qJXuWn' onChange={() => verifyCallback(props.updateForm)} />
                {(props.output !== '' || props.error) &&
                  <x.p mt={10}>Error: {props.output || props.error}</x.p>}
                <x.div display='flex' flexDirection='row'>
                  <x.input mt={15} mr={10} mb={15} bg='red' p={10} text='medium' color='white' textTransform='uppercase' fontWeight='medium' w={isMobile ? '100' : '150'} id='submitbutton' type='submit' value='Submit' />
                  {isMobile &&
                    <x.button
                      aria-label="Don't show again newsletter subscribe" mt={15} mb={15} ml={10} bg='red' p={10} text='medium' color='white' textTransform='uppercase' fontWeight='medium' w='60%' onClick={() => {
                        props.handleIgnoreSubscription()
                        setIsSubscribePopupOpen(false)
                      }}
                    >
                      Don't show again
                    </x.button>}
                </x.div>
                <input type='hidden' name='form_hash' value='n1opdyilO0bEvwBq' id='form_hash' />
              </x.form>
            </x.div>}
          {props.isSubmitting &&
            <x.div w='100%' maxWidth={1220} mx='auto' mb={50}>
              <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Please wait</x.h1>
              <x.p>Submitting your subscription request</x.p>
            </x.div>}
          {props.hasSubmitted &&
            <x.div w='100%' maxWidth={1220} mx='auto' mb={50}>
              <x.h1 text='h1' borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' pb={10} mb={20}>Thank you for your subscription</x.h1>
              <x.p>Please check your email inbox to verify your subscription.</x.p>
            </x.div>}
        </Popup>}
    </>
  )
}

const mapStateToProps = (state) => ({ ...state.subscribe, sessionId: getSessionId(state), visitorId: getVisitorId(state) })
const mapDispatchToProps = (dispatch) => bindActionCreators({ subscribeNewsletter, reinitialize, updateForm, toggleNewsletterSubscription }, dispatch)

export const NewsletterSubscribeCallToAction = connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscribeCallToActionCmp)
export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscribePopup)
